/**
 * @render react
 * @name FlexiExpansionPanel
 * @description Custom Expandable Component
 * @example
 *  <div style={{ width: 300 }}>
 <FlexiExpansionPanel title={"Ez nem tőr!"} count={1}>
 Ez gyros-os kard!
 </FlexiExpansionPanel>
 </div>
 */

import React, { forwardRef, useImperativeHandle, useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlexiTypography from "../FlexiTypography";
import Chip from "@material-ui/core/Chip";
import {
  Grid,
  IconButton,
  isWidthDown,
  withStyles,
  withWidth
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const ExpansionWithDisabled = withStyles({
  root: {
    "&:before": {
      height: 0,
      backgroundColor: "transparent"
    },
    backgroundColor: "transparent",
    "&$disabled": {
      backgroundColor: "transparent"
    }
  },
  disabled: {}
})(ExpansionPanel);

const FlexiExpansionPanel = ({
  title,
  children,
  count,
  width,
  expansionRef,
  defaultExpanded
}) => {
  const useStyles = makeStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: "flex-start"
    },
    content: { flexGrow: isWidthDown("sm", width) ? 1 : 0 }
  });
  const [expanded, setExpanded] = useState(Boolean(defaultExpanded));
  const classes = useStyles({ width });

  useImperativeHandle(expansionRef, () => ({
    open: () => setExpanded(true),
    close: () => setExpanded(false)
  }));

  return (
    <Grid item>
      <ExpansionWithDisabled
        disabled={count === 0}
        elevation={0}
        defaultExpanded={Boolean(defaultExpanded || count)}
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(Boolean(expanded))}
      >
        <ExpansionPanelSummary
          style={{
            alignItems: "center",
            minHeight: "initial",
            maxHeight: "50px"
          }}
          classes={{ content: classes.content, root: classes.root }}
          expandIcon={
            <Grid container>
              <Grid
                item
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex"
                }}
              >
                <IconButton size={"small"} disableRipple>
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        >
          <Grid container alignItems={"flex-end"}>
            <Grid item alignSelf={"center"}>
              <FlexiTypography fontSize={20} variant={"h3"}>
                {title}
              </FlexiTypography>
            </Grid>
            <Grid item>
              <Chip
                label={count}
                size={"small"}
                color={"primary"}
                style={{
                  backgroundColor: "#E90247",
                  marginLeft: 8
                }}
              />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ paddingLeft: 0, paddingRight: 0 }}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionWithDisabled>
    </Grid>
  );
};

FlexiExpansionPanel.propTypes = {
  children: PropTypes.any.isRequired,
  count: PropTypes.number.isRequired,
  expanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

const EnhancedComponent = withWidth()(FlexiExpansionPanel);

export default forwardRef((props, ref) => (
  <EnhancedComponent {...props} expansionRef={ref} />
));
