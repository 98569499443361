import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { required } from "../../utils/validators";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import useRedirect from "../../hooks/useRedirect";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { useLoginPageData, useMutator } from "../../hooks/useApi";
import FlexiLink from "../../FlexiComponents/FlexiLink";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import { resetTargetUrl, storeSsoToken } from "../../utils/authentication";
import DesktopWelcomeHeader from "../../components/WelcomeHeader/desktop";
import MobileWelcomeHeader from "../../components/WelcomeHeader/mobile";
import Footer from "../../components/Footer";
import PasswordField from "../../components/PasswordField";
import loginDoor from "../../images/szamlazzhu/bill_login.svg";
import szamlazzLogo from "../../images/szamlazzhu/szlahu2/szlahu_orange_hor.svg";
import kulcsSoftLogo from "../../images/kulcssoftlogo.png";
import { Divider, Paper } from "@material-ui/core";
import { getUrl } from "../../utils/urlHelpers";
import { getConfig, szamlazzhuInUrl } from "../../utils/config";

const LoginPage = ({ width }) => {
  const notifyError = useErrorOccurredNotification();
  const [redirectEl, redirect] = useRedirect();
  const [doLogin, loading] = useLoginPageData();
  const [get, getLoading] = useMutator("GET");
  const [post, postLoading] = useMutator("POST");
  let targetUrl = localStorage.getItem("targetUrl");
  const szamlazzhuPrefix = getConfig("APP_ENV") !== "production" ? "dev." : "";
  return (
    <Grid container>
      {isWidthDown("sm", width)
        ? redirectEl || (
            <Grid container>
              <Grid item xs={12} style={{ paddingBottom: "105px" }}>
                <MobileWelcomeHeader />
              </Grid>
              <Grid item xs={12}>
                {loading && <FlexiLinearProgress />}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify={"center"}
                  style={{ padding: "0 1rem 1rem" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant={"h5"}
                      align={"center"}
                      style={{ marginBottom: 30 }}
                    >
                      Bejelentkezés
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Form
                      onSubmit={async values => {
                        const { data: loginResponse, error } = await doLogin(
                          values
                        );
                        if (loginResponse) {
                          if (!loginResponse.user.companies.length) {
                            redirect("/external-sales");
                          } else if (
                            targetUrl &&
                            !targetUrl.includes("external-sales")
                          ) {
                            redirect(targetUrl);
                          } else {
                            redirect("/app");
                          }
                          resetTargetUrl();
                        } else {
                          notifyError(selectErrorNotificationMessage(error));
                          return selectFieldErrorsFromResponse(error);
                        }
                      }}
                      render={({ handleSubmit }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Field
                                  name={"username"}
                                  component={TextField}
                                  validate={required}
                                  label={"E-mail cím"}
                                  style={{ marginBottom: 30 }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name={"password"}
                                  component={PasswordField}
                                  validate={required}
                                  label={"Jelszó"}
                                  style={{ marginBottom: 30 }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid container justify={"flex-end"}>
                              <Typography
                                style={{
                                  fontSize: "12px"
                                }}
                              >
                                <FlexiLink
                                  style={{
                                    color: "#2227AC",
                                    textDecoration: "none",
                                    fontWeight: "bold"
                                  }}
                                  to="/forgotten-password"
                                >
                                  Elfelejtett jelszó
                                </FlexiLink>
                              </Typography>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                  marginTop: "42px",
                                  zIndex: 9999
                                }}
                              >
                                <Button
                                  fullWidth
                                  variant={"contained"}
                                  type={"submit"}
                                >
                                  Bejelentkezés
                                </Button>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                                <Button
                                  fullWidth
                                  variant={"outlined"}
                                  onClick={() =>
                                    get(
                                      getUrl("/szamlazzhu/sso/request-token")
                                    ).then(({ data }) => {
                                      const f = document.createElement("form");
                                      f.action = `https://${szamlazzhuPrefix}szamlazz.hu/szamla/main?action=ssologin`;
                                      f.method = "POST";
                                      f.target = "_blank";

                                      const i = document.createElement("input");
                                      i.type = "hidden";
                                      i.name = "ssotoken";
                                      i.value = data.token;
                                      f.appendChild(i);

                                      storeSsoToken(data.token);

                                      document.body.appendChild(f);
                                      f.submit();
                                    })
                                  }
                                >
                                  Bejelentkezés Számlázz.hu fiókkal
                                </Button>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                                <Button
                                  variant={"outlined"}
                                  fullWidth
                                  onClick={() =>
                                    get(
                                      getUrl(
                                        "/kulcssoft/sso/request-authorize-uri"
                                      )
                                    ).then(({ data }) => {
                                      window.open(data.authorizeUri);
                                    })
                                  }
                                >
                                  Bejelentkezés KulcsSoft fiókkal
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      position: window.innerHeight > 630 && "fixed",
                      bottom: "27px"
                    }}
                  >
                    <Footer />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        : redirectEl || (
            <Grid container direction={"row"}>
              <Grid item xs={6}>
                <DesktopWelcomeHeader />
              </Grid>
              <Grid item xs>
                <Grid item container alignItems={"center"} justify={"center"}>
                  <Grid item xs>
                    {loading && <FlexiLinearProgress />}
                    <Grid
                      container
                      justify={"center"}
                      style={{
                        padding: "0 1rem 1rem",
                        width: "410px",
                        margin: "auto",
                        textAlign: "center"
                      }}
                    >
                      <Grid item xs={12} style={{ paddingTop: "40px" }}>
                        <img
                          alt={"loginDoor"}
                          src={loginDoor}
                          style={{ width: "150px" }}
                        />
                        <Typography
                          variant={"h5"}
                          align={"center"}
                          style={{ marginTop: 40, marginBottom: 32 }}
                        >
                          Bejelentkezés
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Form
                          onSubmit={async values => {
                            const {
                              data: loginResponse,
                              error
                            } = await doLogin(values);
                            if (loginResponse) {
                              if (!loginResponse.user.companies.length) {
                                redirect("/external-sales");
                              } else if (
                                targetUrl &&
                                !targetUrl.includes("external-sales")
                              ) {
                                redirect(targetUrl);
                              } else {
                                redirect("/app");
                              }
                              resetTargetUrl();
                            } else {
                              notifyError(
                                selectErrorNotificationMessage(error)
                              );
                              return selectFieldErrorsFromResponse(error);
                            }
                          }}
                          render={({ handleSubmit }) => {
                            return (
                              <form onSubmit={handleSubmit}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Field
                                      name={"username"}
                                      component={TextField}
                                      validate={required}
                                      label={"E-mail cím"}
                                      style={{ marginBottom: 12 }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      name={"password"}
                                      component={PasswordField}
                                      validate={required}
                                      label={"Jelszó"}
                                      style={{ marginBottom: 32 }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: 32 }}>
                                  <Grid item xs={12}>
                                    <Button
                                      variant={"contained"}
                                      type={"submit"}
                                      style={{ margin: "auto" }}
                                    >
                                      Bejelentkezés
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid container justify={"center"}>
                                  <Typography variant={"body2"}>
                                    <FlexiLink
                                      style={{
                                        color: "#2227AC",
                                        textDecoration: "none",
                                        fontWeight: "600"
                                      }}
                                      to="/forgotten-password"
                                    >
                                      Elfelejtett jelszó
                                    </FlexiLink>
                                  </Typography>
                                </Grid>
                              </form>
                            );
                          }}
                        />
                      </Grid>
                      {szamlazzhuInUrl && (
                        <React.Fragment>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "inline-flex",
                              margin: "16px 0px"
                            }}
                            alignItems={"center"}
                            justify={"space-between"}
                          >
                            <Divider style={{ width: "120px" }} />
                            <Typography
                              variant={"body1"}
                              style={{ color: "#858585" }}
                            >
                              VAGY
                            </Typography>
                            <Divider style={{ width: "120px" }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Paper
                              style={{
                                padding: "15px 0px 15px 20px",
                                margin: 0,
                                display: "inline-flex",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                get(
                                  getUrl("/szamlazzhu/sso/request-token")
                                ).then(({ data }) => {
                                  const f = document.createElement("form");
                                  f.action = `https://${szamlazzhuPrefix}szamlazz.hu/szamla/main?action=ssologin`;
                                  f.method = "POST";
                                  f.target = "_blank";

                                  const i = document.createElement("input");
                                  i.type = "hidden";
                                  i.name = "ssotoken";
                                  i.value = data.token;
                                  f.appendChild(i);

                                  storeSsoToken(data.token);

                                  document.body.appendChild(f);
                                  f.submit();
                                })
                              }
                            >
                              <img
                                src={szamlazzLogo}
                                style={{ width: "20%", paddingRight: "20px" }}
                              />
                              <Typography
                                variant={"h6"}
                                style={{ lineHeight: "200%" }}
                              >
                                Bejelentkezés Számlázz.hu fiókkal
                              </Typography>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper
                              style={{
                                padding: "15px 0px 15px 20px",
                                margin: 0,
                                display: "inline-flex",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                get(
                                  getUrl("/kulcssoft/sso/request-authorize-uri")
                                ).then(({ data }) => {
                                  window.open(data.authorizeUri);
                                })
                              }
                            >
                              <img
                                src={kulcsSoftLogo}
                                style={{ width: "20%", paddingRight: "20px" }}
                              />
                              <Typography
                                variant={"h6"}
                                style={{ lineHeight: "200%" }}
                              >
                                Bejelentkezés KulcsSoft fiókkal
                              </Typography>
                            </Paper>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
    </Grid>
  );
};

export default withWidth()(LoginPage);
