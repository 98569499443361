import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { defineMessages } from "react-intl.macro";
import { useIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, { useRef } from "react";
import AgreementSelectField from "../../components/FormFields/AgreementSelectField";
import Portal from "@material-ui/core/Portal";
import { Form } from "react-final-form";
import Grid from "@material-ui/core/Grid";

const messages = defineMessages({
  title: {
    id: "dialogTitle.massUpload.chooseCustomer",
    defaultMessage: "Vevő kiválasztása"
  },
  description: {
    id: "dialogDescription.massUpload.chooseCustomer",
    defaultMessage:
      "Válaszd ki azt a vevőt, akinek a számláit fel szeretnéd tölteni! Egyszerre csak egy szerződéshez tudsz számlákat feltölteni, ezért kérlek, győzdj meg róla, hogy a fájlban szereplő számlák ugyanazon vevőhöz tartoznak!"
  },
  submitButtonText: {
    id: "label.massUpload.selectAgreement",
    defaultMessage: "Kiválasztom"
  }
});

const MassUploadAgreementSelectDialog = ({
  open,
  onClose,
  onAgreementSelected
}) => {
  const intl = useIntl();
  const dialogActionsRef = useRef();

  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={intl.formatMessage(messages.title)}
      dialogActions={<div ref={dialogActionsRef} />}
    >
      <Grid container style={{ maxWidth: 680 }}>
        <Grid item xs={12}>
          <Typography>{intl.formatMessage(messages.description)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Form
            onSubmit={onAgreementSelected}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <AgreementSelectField />
                  <Portal container={dialogActionsRef.current}>
                    <Button onClick={form.submit}>
                      {intl.formatMessage(messages.submitButtonText)}
                    </Button>
                  </Portal>
                </form>
              );
            }}
          />
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default MassUploadAgreementSelectDialog;
