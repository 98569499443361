import React, { createRef, useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import PageLayout from "../../components/PageLayout";
import { useIntl } from "react-intl";
import invoice from "../../images/redesignImages/Icon.svg";
import uploadIconImage from "../../images/docUpload.svg";
import Grid from "@material-ui/core/Grid";
import {
  useAgreements,
  useInvoiceCount,
  useInvoicePageData,
  useMutator,
  useProfileData
} from "../../hooks/useApi";
import { defineMessages } from "react-intl.macro";
import InvoiceIcon from "@material-ui/icons/InsertDriveFile";
import useRoleFilter from "../../hooks/useRoleFilter";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_CUSTOMER_PLUS,
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import usePopupState from "../../hooks/usePopupState";
import FlexiExpandableRemoteList, {
  makeLongTextColumnDefinitionConfig
} from "../../FlexiComponents/FlexiExpandableRemoteList";
import Typography from "@material-ui/core/Typography";
import { getStoredToken } from "../../utils/authentication";
import ErrorBoundary from "../../components/ErrorBoundary";
import DocumentsIcon from "@material-ui/icons/Description";
import EyeIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import PaymentIcon from "@material-ui/icons/Payment";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import {
  useErrorOccurredNotification,
  useWarningNotification
} from "../../hooks/useNotificationState";
import {
  WaitingForCustomerSignature,
  WaitingForCustomerConfirmation,
  WaitingForSupplierSignature,
  Approved,
  WaitingForBoConfirmation,
  Rejected,
  OnHold,
  Granted
} from "./InvoiceStatusIcons";
import closedSvg from "../../images/redesignImages/Rejected.svg";
import invoicesSvg from "../../images/onboarding_szamlak.svg";
import InvoiceUploadTypeDialog from "./InvoiceUploadTypeDialog";
import useRedirect from "../../hooks/useRedirect";
import MassUploadAgreementSelectDialog from "./MassUploadAgreementSelectDialog";
import { useStoreData } from "../../hooks/useDataState";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import useTourState from "../../hooks/useTourState";
import UploadAttachmentsDialog from "../../components/UploadAttachmentsDialog";
import FactoringProcessStartedDialog from "./FactoringProcessStartedDialog";
import formatDate from "../../utils/formatDate";
import makeInvoiceIdRenderer from "../../cellRenderers/makeInvoiceIdRenderer";
import SubmittedSuccessDialog from "./SubmittedSuccessDialog";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { Box, isWidthDown, withWidth } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import NavInfoBar from "../../components/NavInfoBar";
import FactorProcessStartedDialog from "./FactorProcessStartedDialog";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import FlexiFileUploader from "../../FlexiComponents/FlexiFileUploader";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import InvoiceStatusHelperDialog from "./InvoiceStatusHelperDialog";
import { useConfirmation } from "../../FlexiComponents/FlexiConfirm";

const StyledBadge = withStyles(theme => {
  return {
    root: {
      marginTop: "2px",
      "& span": {
        backgroundColor: "#eaaf40",
        color: "#fff"
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "8px"
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "8px"
      }
    }
  };
})(Badge);
const StyledRedBadge = withStyles(theme => {
  return {
    root: {
      marginTop: "10px",
      "& span": {
        backgroundColor: "#F7471E",
        color: "#fff"
      }
    }
  };
})(Badge);

const listRefs = [createRef(), createRef(), createRef(), createRef()];

const refreshList = () => {
  listRefs
    .filter(ref => ref.current)
    .forEach(item => {
      item.current.reload();
    });
};

const messages = defineMessages({
  tabPENDING: {
    id: "tabLabels.invoiceList.PENDING",
    defaultMessage: "Feltöltve"
  },
  tabPROGRESSING: {
    id: "tabLabels.invoiceList.PROGRESSING",
    defaultMessage: "Benyújtott"
  },
  tabCOMPLETED: {
    id: "tabLabels.invoiceList.COMPLETED",
    defaultMessage: "Faktorált"
  },
  tabDUED: {
    id: "tabLabels.invoiceList.DUED",
    defaultMessage: "Lejárt"
  },
  tabCLOSED: {
    id: "tabLabels.invoiceList.CLOSED",
    defaultMessage: "Elszámolt (lezárt)"
  },
  pageTitle: { id: "pageTitle.invoice", defaultMessage: "Számlák" }
});

export const makeStatusIconRenderer = () => ({ value, data }) => {
  const getStatusIcon = () => {
    switch (value) {
      case "WAITING_FOR_CUSTOMER_SIGNATURE":
        return <WaitingForCustomerSignature />;
      case "WAITING_FOR_CUSTOMER_CONFIRMATION":
        return <WaitingForCustomerConfirmation />;
      case "WAITING_FOR_SUPPLIER_SIGNATURE":
        return (
          <StyledBadge badgeContent={"!"}>
            <WaitingForSupplierSignature />
          </StyledBadge>
        );
      case "APPROVED":
        return <Approved />;
      case "WAITING_FOR_ADMIN_CONFIRMATION":
        return <WaitingForBoConfirmation />;
      case "REJECTED":
        return <Rejected />;
      case "ON_HOLD":
        return <OnHold />;
      default:
        return "";
    }
  };

  if (data.hasRejectedInvoice) {
    return <StyledBadge badgeContent={"!"}>{getStatusIcon()}</StyledBadge>;
  }
  if (data.totalAdvAmount === 0 && (value === "APPROVED" || value === "PAID")) {
    return <Granted />;
  }

  if (data.closed) {
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    return (
      <>
        <Tooltip title={"Lezárt"}>
          <img
            style={{ cursor: "help" }}
            onClick={() => setStatusDialogOpen(true)}
            src={closedSvg}
            alt={"Lezárva"}
          />
        </Tooltip>
        <InvoiceStatusHelperDialog
          open={statusDialogOpen}
          onClose={() => setStatusDialogOpen(false)}
        />
      </>
    );
  }

  return <span>{getStatusIcon()}</span>;
};

const InvoicePage = ({ match, width }) => {
  const storeData = useStoreData();
  const [groupResponse, setGroupResponse] = useState(null);
  const [post, postLoading] = useMutator("POST");
  const [redirectEl, redirect] = useRedirect();
  const [attachedFiles, setAttachedFiles] = React.useState([]);
  const [massUploadFile, setMassUploadFile] = useState(null);
  const notifyError = useErrorOccurredNotification();
  const notifyWarning = useWarningNotification();
  const [tourState] = useTourState();
  const { mainCompany, szamlazzHu, kulcsSoft } = useProfileData();
  const navConnected = mainCompany.isNavUserDataFilled;
  const isMobile = isWidthDown("md", width);
  const showNavInfoBar = !mainCompany?.defaultSettings
    .loadNavOutgoingInvoicesToFlexiPay;

  const [
    factorNotificationGenerating,
    setFactorNotificationGenerating
  ] = useState(false);

  const [
    agreementSelectShow,
    showAgreementSelect,
    hideAgreementSelect
  ] = usePopupState("MassUploadAgreementSelectDialog");

  const [
    submittedSuccessDialogShow,
    showSubmittedSuccessDialog,
    hideSubmittedSuccessDialog
  ] = usePopupState("SubmittedSuccessDialog");

  const [
    missingDocumentsOpen,
    showMissingDocuments,
    hideMissingDocuments
  ] = usePopupState("UploadAttachmentsDialog");

  const [
    processStartedOpen,
    showProcessStarted,
    hideProcessStarted
  ] = usePopupState("FactoringProcessStartedDialog");

  const {
    uploadMassInvoice,
    reloadInvoices,
    startGrouping,
    deleteInvoice,
    loading,
    uploading
  } = useInvoicePageData(groupResponse);

  const deleteWithConfirm = async id => {
    const { data, error } = await deleteInvoice(id);

    if (data) {
      refreshList();
    } else {
      notifyError(selectErrorNotificationMessage(error));
    }
  };

  const [uploadTypeShow, showUploadType, hideUploadType] = usePopupState(
    "InvoiceUploadTypeDialog"
  );

  const [
    factorProcessStartedShow,
    showFactorProcessStarted,
    hideFactorProcessStarted
  ] = usePopupState("FactorProcessStartedDialog");

  const startUploadingMassInvoice = async agreementId => {
    const { data: massUploadResponse, error } = await uploadMassInvoice(
      massUploadFile
    );

    if (!massUploadResponse) {
      notifyError(selectErrorNotificationMessage(error));
    } else {
      storeData("parsedMassInvoices", {
        data: massUploadResponse,
        fileName: massUploadFile.name
      });
      redirect(`/app/save-mass-invoice/${agreementId}`);
    }
  };

  const intl = useIntl();
  const hasPermission = useHasPermission();
  const { loading: invoicesLoading, count } = useInvoiceCount();
  const confirm = useConfirmation();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);

  const rightSideComponents = [];
  if ((!szamlazzHu && !kulcsSoft) || (isScf && !invoicesLoading)) {
    rightSideComponents.push({
      // roles: [
      //   ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
      //   ROLE_FLEXIPAY_INVOICE_MANAGER,
      //   ROLE_FLEXIPAY_SUPER_ADMIN,
      //   ROLE_FLEXIPAY_CUSTOMER_PLUS
      // ],
      props: {
        children: (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <img alt="invoice" src={invoice} style={{ paddingRight: "6px" }} />{" "}
            <span style={{ lineHeight: 1.1 }}>Új számla feltöltése</span>
          </Box>
        ),
        onClick: showUploadType
      },
      mobile: {
        placement: "fab",
        icon: <InvoiceIcon />,
        tooltip: "Számla"
      }
    });
  }

  const filteredRightSideComponents = useRoleFilter(rightSideComponents);

  const dateValueFormatter = ({ value }) => formatDate(value);

  const [missingDocumentsInvoices, setMissingDocumentInvoices] = useState([]);
  const [groupError, setGroupError] = useState(null);
  const [prepareResult, setPrepareResult] = useState(null);
  const { data: agreements } = useAgreements();
  const startGroupInvoices = async (
    selectedInvoices,
    files,
    existingInvoiceGroup
  ) => {
    const { data: groupResponse, error: groupError } = await startGrouping({
      invoices: selectedInvoices,
      files: files || attachedFiles,
      existingInvoiceGroup,
      onPrepareSuccess: prepare => {
        setPrepareResult(prepare);
      }
    });

    setGroupResponse(groupResponse);
    if (groupResponse) {
      setGroupError(null);
      // listInstance.api.deselectAll();
      hideMissingDocuments();

      if (groupResponse?.isFinanceNotificationRequired) {
        if (groupResponse?.financeNotification) {
          if (
            groupResponse?.financeNotification
              .generateStatusForSupplierDocument === "SUCCESS"
          ) {
            redirect(
              `/app/invoice-group/${groupResponse.id}/factor-notification`
            );
          } else {
            redirect(`/app/invoice-group/${groupResponse.id}`);
            notifyWarning({
              text: "A faktorálási értesítő még nem generálódott le!"
            });
          }
        } else {
          showProcessStarted();
        }
      } else {
        showFactorProcessStarted();
      }

      await reloadInvoices();
    } else if (
      groupError.message === "Missing documents from selected invoice(s)"
    ) {
      setMissingDocumentInvoices(selectedInvoices);
      setGroupError(groupError.response);
      showMissingDocuments();
    } else {
      notifyError(selectErrorNotificationMessage(groupError));
    }

    refreshList();
  };

  const isInvoiceManager = hasPermission([ROLE_FLEXIPAY_INVOICE_MANAGER]);
  const isAdmin = hasPermission([ROLE_FLEXIPAY_SUPER_ADMIN]);
  function isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    return false;
  }

  useEffect(() => {
    if (match.params.tabName === "showPopup") {
      showUploadType();
    }
  }, [match.params.tabName]);

  const anyLoading = loading || postLoading || factorNotificationGenerating;
  console.log(szamlazzHu);
  return (
    redirectEl || (
      <PageLayout
        insidePage
        pay
        className="invoice-list"
        titleMessage={messages.pageTitle}
        disabledBottomMargin
        rightSideComponents={filteredRightSideComponents}
        loading={anyLoading}
      >
        {!szamlazzHu ||
          !kulcsSoft ||
          (!isScf && (
            <div style={{ marginTop: "24px" }}>
              <NavInfoBar
                navConnected={navConnected}
                refreshList={refreshList}
              />
            </div>
          ))}
        <>
          {isScf ? (
            <div
              style={{
                marginTop: isWidthDown("sm", width) ? "1rem" : "2rem"
              }}
            >
              <div style={{ marginBottom: "1.9rem" }}>
                {/* FELTÖLTÖTT */}
                <ErrorBoundary>
                  <FlexiExpandableRemoteList
                    gridOptions={{
                      suppressRowClickSelection: false,
                      defaultColDef: {
                        headerCheckboxSelection: isFirstColumn,
                        checkboxSelection: isFirstColumn
                      }
                    }}
                    hideSearchBar
                    ref={listRefs[0]}
                    url={getUrlWithCompanyId(
                      "/scfplus/invoice/prepared-customer"
                    )}
                    token={getStoredToken()}
                    title={"Feltöltött számlák"}
                    columnDefs={[
                      {
                        headerName: "Szállító neve",
                        field: "supplierName",
                        checkboxSelection: true,
                        cellClass: "cell-wrap-text multi-line left-cell",
                        minWidth: 270,
                        ...makeLongTextColumnDefinitionConfig({
                          tooltip: true,
                          lines: 2
                        })
                      },
                      {
                        headerName: "Számla száma",
                        field: "invoiceId",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text",
                        cellRendererFramework: makeInvoiceIdRenderer(invoice =>
                          redirect(`/app/invoice/documents/${invoice.id}`)
                        )
                      },
                      {
                        headerName: "Bruttó összeg",
                        field: "totalGrossAmount",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: {
                              ...data,
                              currency: data?.agreement?.currency
                            }
                          })
                      },
                      {
                        headerName: "Beszámítás összege",
                        field: "totalAdvAmount",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: {
                              ...data,
                              currency: data?.agreement?.currency
                            }
                          })
                      },
                      {
                        headerName: "Lejárat",
                        field: "dueDate",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text",
                        valueFormatter: dateValueFormatter
                      },
                      {
                        headerName: "",
                        width: 66,
                        minWidth: 66,
                        field: "id",
                        menu: true,
                        getMenuItems: item => {
                          const items = [
                            {
                              id: "invoices-list-delete-invoice",
                              icon: <DeleteIcon />,
                              text: "Törlés",
                              confirmText: "A törlés nem visszavonható!",
                              onClick: () => deleteWithConfirm(item.id)
                            },
                            {
                              id: "invoices-list-show-documents",
                              icon: <DocumentsIcon />,
                              text: "Dokumentumok",
                              onClick: () =>
                                redirect(`/app/invoice/documents/${item.id}`)
                            }
                          ];
                          return items;
                        },
                        cellClass: "cell-wrap-text"
                      }
                    ]}
                    bulkActions={[
                      {
                        icon: <AssignmentTurnedInIcon />,
                        text: "Továbbítás",
                        onClick: async selectedItems => {
                          // TODO
                          const invoicesIdArr = [];

                          selectedItems.map(item => {
                            invoicesIdArr.push(item.id);
                          });

                          const {
                            data,
                            error
                          } = await post(
                            getUrlWithCompanyId("/scfplus/invoice/submit"),
                            { invoices: invoicesIdArr }
                          );

                          if (error) {
                            notifyError(selectErrorNotificationMessage(error));
                          }

                          if (data) {
                            showSubmittedSuccessDialog();
                          }
                          refreshList();
                        }
                      }
                    ]}
                    FlexiInfoBarProps={{
                      info: true,
                      children:
                        "Jelölje ki a számlákat, amiket továbbítani szeretne a szállító felületére!"
                    }}
                  />
                </ErrorBoundary>
              </div>
              <div style={{ marginBottom: "1.9rem" }}>
                {/* SZÁLLÍTÓNAK TOVÁBBÍTOTT */}
                <ErrorBoundary>
                  <FlexiExpandableRemoteList
                    rowsPerPageOptions={[25, 50, 100]}
                    gridOptions={{
                      rowMultiSelectWithClick: true,
                      suppressCellSelection: true,
                      suppressRowClickSelection: isMobile,
                      onCellClicked: e => {
                        if (e.colDef.menu) {
                          e.node.setSelected(!e.node.selected);
                        }
                      },
                      defaultColDef: {
                        headerCheckboxSelection:
                          isInvoiceManager && !isAdmin ? false : isFirstColumn,
                        checkboxSelection: isFirstColumn
                      }
                    }}
                    hideSearchBar
                    advancedFilterEnabled
                    ref={listRefs[1]}
                    url={getUrlWithCompanyId("/scfplus/invoice/prepared")}
                    token={getStoredToken()}
                    title={"Szállítónak továbbított számlák"}
                    columnDefs={[
                      {
                        headerName: "Szállító neve",
                        field: "supplierName",
                        cellClass:
                          "cell-wrap-text cursor-pointer multi-line left-cell",
                        filterConfig: {
                          type: "text",
                          collapsed: false,
                          order: 1
                        },
                        checkboxSelection: true,
                        minWidth: 240,
                        ...makeLongTextColumnDefinitionConfig({
                          tooltip: true,
                          lines: 2
                        })
                      },
                      {
                        headerName: "Számla száma",
                        field: "invoiceId",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        cellRendererFramework: makeInvoiceIdRenderer(invoice =>
                          redirect(`/app/invoice/documents/${invoice.id}`)
                        )
                      },
                      {
                        headerName: "Bruttó összeg",
                        field: "totalGrossAmount",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data?.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Beszámítás összege",
                        field: "initialCompensation",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data?.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Lejárat",
                        field: "dueDate",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: dateValueFormatter,
                        minWidth: 120
                      },
                      {
                        headerName: "",
                        width: 66,
                        minWidth: 66,
                        menu: true,
                        getMenuItems: item => {
                          const items = [
                            {
                              id: "invoices-list-delete-invoice",
                              icon: <DeleteIcon />,
                              text: "Törlés",
                              confirmText: "A törlés nem visszavonható!",
                              onClick: () => deleteWithConfirm(item.id)
                            },
                            {
                              id: "invoices-list-show-documents",
                              icon: <DocumentsIcon />,
                              text: "Dokumentumok",
                              onClick: () =>
                                redirect(`/app/invoice/documents/${item.id}`)
                            }
                          ];
                          return items;
                        }
                      }
                    ]}
                  />
                </ErrorBoundary>
              </div>
              <div style={{ marginBottom: "1.9rem" }}>
                {/* FIZETENDŐ */}
                <ErrorBoundary>
                  <FlexiExpandableRemoteList
                    gridOptions={{
                      suppressRowClickSelection: true
                    }}
                    hideSearchBar
                    ref={listRefs[3]}
                    url={getUrlWithCompanyId("/scfplus/invoice/payable")}
                    token={getStoredToken()}
                    title={"Fizetendő számlák"}
                    columnDefs={[
                      {
                        headerName: "Szállító neve",
                        field: "supplierName",
                        cellClass:
                          "cell-wrap-text cursor-pointer multi-line left-cell",
                        filterConfig: {
                          type: "text",
                          collapsed: false,
                          order: 1
                        },
                        checkboxSelection: true,
                        minWidth: 240,
                        ...makeLongTextColumnDefinitionConfig({
                          tooltip: true,
                          lines: 2
                        })
                      },
                      {
                        headerName: "Számla száma",
                        field: "invoiceId",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        cellRendererFramework: makeInvoiceIdRenderer(invoice =>
                          redirect(`/app/invoice/documents/${invoice.id}`)
                        )
                      },
                      {
                        headerName: "Bruttó összeg",
                        field: "totalGrossAmount",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data?.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Beszámítás összege",
                        field: "initialCompensation",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data?.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Lejárat",
                        field: "dueDate",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: dateValueFormatter,
                        minWidth: 120
                      },
                      {
                        headerName: "",
                        width: 66,
                        minWidth: 66,
                        menu: true,
                        getMenuItems: item => {
                          const items = [
                            {
                              id: "invoices-list-show-documents",
                              icon: <DocumentsIcon />,
                              text: "Dokumentumok",
                              onClick: () =>
                                redirect(`/app/invoice/documents/${item.id}`)
                            }
                          ];

                          return items;
                        }
                      }
                    ]}
                  />
                </ErrorBoundary>
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: isWidthDown("sm", width) ? "1rem" : "2rem"
              }}
            >
              {isInvoiceManager &&
                !isAdmin &&
                Boolean(count) &&
                !invoicesLoading && (
                  <div style={{ marginBottom: "1.9rem" }}>
                    {/* FELTÖLTÖTT */}
                    <ErrorBoundary>
                      <FlexiExpandableRemoteList
                        gridOptions={{
                          suppressRowClickSelection: false,
                          defaultColDef: {
                            headerCheckboxSelection: isFirstColumn,
                            checkboxSelection: isFirstColumn
                          }
                        }}
                        hideSearchBar
                        ref={listRefs[0]}
                        url={getUrlWithCompanyId("/invoice/pending")}
                        token={getStoredToken()}
                        title={"Feltöltött számlák"}
                        columnDefs={[
                          {
                            headerName: "Vevő neve",
                            field: "customerName",
                            checkboxSelection: true,
                            cellClass: "cell-wrap-text multi-line left-cell",
                            minWidth: 270,
                            ...makeLongTextColumnDefinitionConfig({
                              tooltip: true,
                              lines: 2
                            })
                          },
                          {
                            headerName: "Számla száma",
                            field: "invoiceId",
                            sortable: true,
                            autoHeight: true,
                            cellClass: "cell-wrap-text",
                            cellRendererFramework: makeInvoiceIdRenderer(
                              invoice =>
                                redirect(`/app/invoice/documents/${invoice.id}`)
                            )
                          },
                          {
                            headerName: "Bruttó összeg",
                            field: "totalGrossAmount",
                            sortable: true,
                            autoHeight: true,
                            cellClass: "cell-wrap-text",
                            valueFormatter: ({ value, data }) =>
                              makeCurrencyValueFormatter(intl)({
                                value,
                                data: {
                                  ...data,
                                  currency: data?.agreement?.currency
                                }
                              })
                          },
                          {
                            headerName: "Beszámítás összege",
                            field: "totalAdvAmount",
                            sortable: true,
                            autoHeight: true,
                            cellClass: "cell-wrap-text",
                            valueFormatter: ({ value, data }) =>
                              makeCurrencyValueFormatter(intl)({
                                value,
                                data: {
                                  ...data,
                                  currency: data?.agreement?.currency
                                }
                              })
                          },
                          {
                            headerName: "Lejárat",
                            field: "dueDate",
                            sortable: true,
                            autoHeight: true,
                            cellClass: "cell-wrap-text",
                            valueFormatter: dateValueFormatter
                          },
                          {
                            headerName: "",
                            width: 66,
                            minWidth: 66,
                            field: "id",
                            roles: [
                              ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
                              ROLE_FLEXIPAY_INVOICE_MANAGER
                            ],
                            menu: true,
                            getMenuItems: item => {
                              const items = [];
                              if (!szamlazzHu || !kulcsSoft) {
                                items.push({
                                  id: "uploaded-invoices-list-edit-documents",
                                  icon: <EditIcon />,
                                  text: "Szerkesztés",
                                  onClick: () =>
                                    redirect(`/app/invoice/edit/${item.id}`)
                                });
                              }
                              if (item.documents.length) {
                                items.push({
                                  id: "invoices-list-show-documents",
                                  icon: <DocumentsIcon />,
                                  text: "Dokumentumok",
                                  onClick: () =>
                                    redirect(
                                      `/app/invoice/documents/${item.id}`
                                    )
                                });
                              }
                              return items;
                            },
                            cellClass: "cell-wrap-text"
                          }
                        ]}
                        bulkActions={[
                          {
                            icon: <AssignmentTurnedInIcon />,
                            text: "Előkészítve",
                            onClick: async selectedItems => {
                              // TODO
                              const invoicesIdArr = [];

                              selectedItems.map(item => {
                                invoicesIdArr.push(item.id);
                              });

                              const {
                                data,
                                error
                              } = await post(
                                getUrlWithCompanyId(
                                  "/invoice/mark-as-prepared"
                                ),
                                { invoices: invoicesIdArr }
                              );

                              if (error) {
                                notifyError(
                                  selectErrorNotificationMessage(error)
                                );
                              }

                              if (data) {
                                showSubmittedSuccessDialog();
                              }
                              refreshList();
                            }
                          }
                        ]}
                        FlexiInfoBarProps={{
                          info: true,
                          children:
                            "Jelölje ki a számlákat az előkészítés végrehajtásához!"
                        }}
                      />
                    </ErrorBoundary>
                  </div>
                )}
              <div style={{ marginBottom: "1.9rem" }}>
                {/* FAKTORÁLHATÓ */}
                <ErrorBoundary>
                  <FlexiExpandableRemoteList
                    rowsPerPageOptions={[25, 50, 100]}
                    gridOptions={{
                      rowMultiSelectWithClick: true,
                      suppressCellSelection: true,
                      suppressRowClickSelection: isMobile,
                      onCellClicked: e => {
                        if (e.colDef.menu) {
                          e.node.setSelected(!e.node.selected);
                        }
                      },
                      defaultColDef: {
                        headerCheckboxSelection:
                          isInvoiceManager && !isAdmin ? false : isFirstColumn,
                        checkboxSelection: isFirstColumn
                      }
                    }}
                    hideSearchBar
                    ref={listRefs[1]}
                    url={getUrlWithCompanyId("/invoice/prepared")}
                    token={getStoredToken()}
                    title={"Faktorálható számlák"}
                    columnDefs={[
                      {
                        headerName: "Vevő neve",
                        field: "customerName",
                        cellClass:
                          "cell-wrap-text cursor-pointer multi-line left-cell",
                        checkboxSelection: isAdmin || !isInvoiceManager,
                        minWidth: 240,
                        ...makeLongTextColumnDefinitionConfig({
                          tooltip: true,
                          lines: 2
                        })
                      },
                      {
                        headerName: "Számla száma",
                        field: "invoiceId",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        cellRendererFramework: makeInvoiceIdRenderer(invoice =>
                          redirect(`/app/invoice/documents/${invoice.id}`)
                        )
                      },
                      {
                        headerName: "Bruttó összeg",
                        field: "totalGrossAmount",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Beszámítás összege",
                        field: "initialCompensation",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: ({ value, data }) =>
                          makeCurrencyValueFormatter(intl)({
                            value,
                            data: { ...data, currency: data.currency }
                          }),
                        minWidth: 120
                      },
                      {
                        headerName: "Lejárat",
                        field: "dueDate",
                        sortable: true,
                        autoHeight: true,
                        cellClass: "cell-wrap-text cursor-pointer",
                        valueFormatter: dateValueFormatter,
                        minWidth: 120
                      },
                      {
                        headerName: "",
                        width: 66,
                        minWidth: 66,
                        roles: [
                          ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
                          ROLE_FLEXIPAY_INVOICE_MANAGER
                        ],
                        menu: true,
                        getMenuItems: item => {
                          const items = [
                            {
                              id: "invoices-list-delete-invoice",
                              icon: <DeleteIcon />,
                              text: "Törlés",
                              confirmText: "A törlés nem visszavonható!",
                              onClick: () => deleteWithConfirm(item.id)
                            }
                          ];
                          if (!szamlazzHu || !kulcsSoft) {
                            items.push({
                              id: "uploaded-invoices-list-edit-documents",
                              icon: <EditIcon />,
                              text: "Szerkesztés",
                              onClick: () =>
                                redirect(`/app/invoice/edit/${item.id}`)
                            });
                          }

                          if (item.documents.length) {
                            items.push({
                              id: "invoices-list-show-documents",
                              icon: <DocumentsIcon />,
                              text: "Dokumentumok",
                              onClick: () =>
                                redirect(`/app/invoice/documents/${item.id}`)
                            });
                          }
                          return items;
                        }
                      }
                    ]}
                    bulkActions={
                      (isAdmin || !isInvoiceManager) && [
                        {
                          icon: <PaymentIcon />,
                          text: "Faktorálás",
                          onClick: async selectedItems => {
                            // TODO
                            const agreement = agreements.find(
                              agreement =>
                                "" + agreement.agreementId ===
                                selectedItems[0].agreementId
                            );
                            const selectedSum = selectedItems.reduce(
                              (sum, item) => sum + item.totalGrossAmount,
                              0
                            );

                            const sendInvoices = async () => {
                              const invoicesIdArr = [];
                              selectedItems.map(item => {
                                invoicesIdArr.push(item.id);
                              });

                              if (tourState.isTourOpen) {
                                return false;
                              } else {
                                await startGroupInvoices(selectedItems);
                              }
                              refreshList();
                            };

                            if (agreement.limitAmount < selectedSum) {
                              confirm({
                                fn: () => sendInvoices(),
                                title: `A "${agreement.agreementName}"-hez tartozó limited betelt`,
                                description:
                                  "A számlád ettől még befogadjuk, de folyósítani csak a limit felszabadulásával fogunk tudni. Részfolyósítást természetesen limitig tudunk biztosítani. Limitemeléssel kapcsolatban kérlek keress bennünket bármikor!",
                                yesButtonText: "Oké",
                                noButtonText: "Mégse"
                              });
                            } else {
                              await sendInvoices();
                            }
                          },
                          disabled: anyLoading
                        }
                      ]
                    }
                    FlexiInfoBarProps={
                      !isInvoiceManager && {
                        info: true,
                        children: "Jelöld ki a számlákat a faktoráláshoz!"
                      }
                    }
                  />
                </ErrorBoundary>
              </div>
              {(!isInvoiceManager || isAdmin) && (
                <div style={{ marginBottom: "1.9rem" }}>
                  {/* FOLYÓSÍTÁSRA VÁRÓ */}
                  <ErrorBoundary>
                    <FlexiExpandableRemoteList
                      // miért kell rá a width, ha a többire nem?
                      style={{ width: "100%" }}
                      gridOptions={{
                        rowSelection: "multiple",
                        suppressMovableColumns: false,
                        suppressRowClickSelection: true,
                        defaultColDef: {
                          resizable: true
                        },
                        rowClassRules: {
                          red: params => {
                            return params.data.hasDueInvoice;
                          },
                          yellow: params => {
                            return (
                              params.data.status ===
                                "WAITING_FOR_SUPPLIER_SIGNATURE" ||
                              params.data.hasRejectedInvoice
                            );
                          }
                        }
                      }}
                      hideSearchBar
                      ref={listRefs[2]}
                      url={getUrlWithCompanyId("/invoice-group/submitted")}
                      token={getStoredToken()}
                      title={"Folyósításra váró számlák"}
                      rowsPerPageOptions={[25]}
                      showMoreLimit={25}
                      columnDefs={[
                        {
                          headerName: "Vevő neve",
                          headerClass: "left-header",
                          field: "agreement.customerName",
                          sortable: false,
                          cellClass:
                            "cell-wrap-text multi-line padding-top left-cell",
                          minWidth: 240,
                          ...makeLongTextColumnDefinitionConfig({
                            tooltip: true,
                            lines: 2
                          })
                        },
                        {
                          headerName: "Számlacsoport",
                          minWidth: 120,
                          field: "faktoriId",
                          sortable: false,
                          cellRendererFramework: params => {
                            return (
                              <span
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <Typography>{params.value}</Typography>
                                {params.data.hasDueInvoice && (
                                  <Tooltip
                                    title={
                                      params.data.hasDueInvoice
                                        ? "Lejárt számla"
                                        : ""
                                    }
                                    placement={"bottom"}
                                  >
                                    <StyledRedBadge
                                      badgeContent={"!"}
                                      style={{
                                        marginLeft: "20px"
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </span>
                            );
                          }
                        },
                        {
                          headerName: "Számlák (db)",
                          minWidth: 100,
                          field: "invoicesCount",
                          autoHeight: true,
                          cellClass: "cell-wrap-text"
                        },
                        {
                          headerName: "Visszaigazolt",
                          minWidth: 100,
                          field: "confirmedInvoicesCount",
                          autoHeight: true,
                          cellClass: "cell-wrap-text"
                        },
                        {
                          headerName: "Számlák összege",
                          minWidth: 120,
                          field: "totalGrossAmount",
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          valueFormatter: ({ value, data }) =>
                            makeCurrencyValueFormatter(intl)({
                              value,
                              data: {
                                ...data,
                                currency: data.agreement.currency
                              }
                            })
                        },
                        {
                          headerName: "Státusz",
                          field: "status",
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          cellRendererFramework: makeStatusIconRenderer()
                        },
                        {
                          headerName: "",

                          roles: [
                            ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
                            ROLE_FLEXIPAY_INVOICE_MANAGER
                          ],
                          cellRendererFramework: item => {
                            return (
                              <span>
                                <IconButton
                                  size={"small"}
                                  onClick={() =>
                                    redirect(
                                      `/app/invoice-group/${item?.data?.id}`
                                    )
                                  }
                                >
                                  <EyeIcon />
                                </IconButton>
                                {item.data.isFinanceNotificationRequired && (
                                  <IconButton
                                    size={"small"}
                                    onClick={() =>
                                      redirect(
                                        `/app/invoice-group/${item?.data?.id}/factor-notification`
                                      )
                                    }
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <DocumentsIcon />
                                  </IconButton>
                                )}
                              </span>
                            );
                          }
                        }
                      ]}
                    />
                  </ErrorBoundary>
                </div>
              )}
              {(!isInvoiceManager || isAdmin) && (
                <div style={{ marginBottom: "1.9rem" }}>
                  {/* FAKTORÁLT */}
                  <ErrorBoundary>
                    <FlexiExpandableRemoteList
                      gridOptions={{
                        suppressRowClickSelection: true
                      }}
                      hideSearchBar
                      ref={listRefs[3]}
                      url={getUrlWithCompanyId("/invoice-group/faktoring")}
                      token={getStoredToken()}
                      title={"Faktorált számlák"}
                      columnDefs={[
                        {
                          headerName: "Vevő neve",
                          field: "financeNotification.agreement.customerName",
                          sortable: false,
                          cellClass: "cell-wrap-text multi-line left-cell",
                          minWidth: 270,
                          ...makeLongTextColumnDefinitionConfig({
                            tooltip: true,
                            lines: 2
                          })
                        },
                        {
                          headerName: "Számlacsoport",
                          field: "faktoriId",
                          sortable: false
                        },
                        {
                          headerName: "Számlák (db)",
                          field: "invoicesCount",
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text"
                        },
                        {
                          headerName: "Számlák összege",
                          field: "totalGrossAmount",
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          valueFormatter: ({ value, data }) =>
                            makeCurrencyValueFormatter(intl)({
                              value,
                              data: {
                                ...data,
                                currency: data.agreement.currency
                              }
                            })
                        },
                        {
                          headerName: "Beszámítással csökkentett összeg",
                          valueGetter: ({ data }) =>
                            data.totalGrossAmount - data.totalCompensation,
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          valueFormatter: ({ value, data }) =>
                            makeCurrencyValueFormatter(intl)({
                              value,
                              data: {
                                ...data,
                                currency: data.agreement.currency
                              }
                            })
                        },
                        {
                          headerName: "Finanszírozott összeg",
                          field: "totalAdvAmount",
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          valueFormatter: ({ value, data }) =>
                            makeCurrencyValueFormatter(intl)({
                              value,
                              data: {
                                ...data,
                                currency: data.agreement.currency
                              }
                            })
                        },
                        {
                          headerName: "Státusz",
                          field: "status",
                          sortable: true,
                          autoHeight: true,
                          cellClass: "cell-wrap-text",
                          cellRendererFramework: makeStatusIconRenderer(),
                          width: 66,
                          minWidth: 66
                        },
                        {
                          headerName: "",
                          width: 66,
                          minWidth: 66,
                          roles: [
                            ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
                            ROLE_FLEXIPAY_INVOICE_MANAGER
                          ],
                          menu: true,
                          getMenuItems: item => {
                            return [
                              // {
                              //   id: "invoices-list-show-documents",
                              //   icon: <DocumentsIcon />,
                              //   text: "Dokumentumok",
                              //   onClick: () =>
                              //     redirect(`/app/invoice/documents/${item.id}`)
                              // },
                              {
                                id: "invoices-list-show-documents",
                                icon: <VisibilityIcon />,
                                text: "Számlák megtekintése",
                                onClick: () =>
                                  redirect(`/app/invoice-group/${item.id}`)
                              },
                              {
                                id: "invoices-list-factor-notification",
                                icon: <DocumentsIcon />,
                                text: "Faktorálási értesítők",
                                onClick: () =>
                                  redirect(
                                    `/app/invoice-group/${item.id}/factor-notification`
                                  )
                              }
                            ];
                          }
                        }
                      ]}
                    />
                  </ErrorBoundary>
                </div>
              )}
            </div>
          )}
          <SubmittedSuccessDialog
            open={submittedSuccessDialogShow}
            onClose={hideSubmittedSuccessDialog}
          />
          <FactorProcessStartedDialog
            open={factorProcessStartedShow}
            onClose={hideFactorProcessStarted}
          />

          <FlexiDialog
            title={"Hiányzó dokumentum"}
            size={"small"}
            open={missingDocumentsOpen}
            onClose={() => {
              hideMissingDocuments();
              setPrepareResult(null);
            }}
          >
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 8, width: "100%" }}
            >
              <Grid item xs={12}>
                <Typography>
                  Mindjárt kész vagy! A számláid faktorálásához már csak néhány
                  fájlt kell feltöltened! Ezt a dobozokban lévő feltöltés ikonra
                  kattintva teheted meg, típusonként elkülönítve. A szükséges
                  dokumentumok az alábbiak:
                </Typography>
              </Grid>
              {groupError?.data.map(item => (
                <Grid item xs={12} style={{ paddingLeft: 9 }}>
                  <FlexiFileUploader
                    immediateUpload
                    dialogPlaceholderText={
                      "Húzd ide a dokumentumot, vagy válaszd ki a “Fájl hozzáadása” gombra kattintva!"
                    }
                    dialogPlaceholderImage={uploadIconImage}
                    onDeleteFile={deleteFn => deleteFn()}
                    getUploadDialogContent={() => (
                      <Typography variant={"h3"} style={{ marginBottom: 12 }}>
                        {item.docType.name}
                      </Typography>
                    )}
                    rightComponent={
                      <Tooltip
                        title={
                          item?.invoices?.length &&
                          `Számlák: ${item.invoices.join(", ")}`
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    }
                    title={item?.docType?.name}
                    onSubmit={async values => {
                      const newFiles = values.files.map(file => ({
                        file,
                        type: item?.id ? "custom" : item?.docType?.type,
                        customDocumentType: item?.docType?.id
                      }));
                      setAttachedFiles(newFiles);
                      await startGroupInvoices(
                        missingDocumentsInvoices,
                        newFiles,
                        prepareResult
                      );
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </FlexiDialog>
          <FactoringProcessStartedDialog
            open={processStartedOpen}
            onClose={hideProcessStarted}
            customerName={groupResponse?.agreement?.customerName}
          />
        </>
        {!isScf && !count && !invoicesLoading && (
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            style={{ paddingTop: "86px" }}
          >
            <Grid item style={{ paddingBottom: "34px" }}>
              <img alt={"invoicesSvg"} src={invoicesSvg} />
            </Grid>
            <Grid item style={{ paddingBottom: "12px" }}>
              {!szamlazzHu || !kulcsSoft ? (
                <Typography variant={"h3"}>
                  Faktorálással gyorsan és egyszerűen hozzájutsz a kiállított
                  számláid ellenértékéhez
                </Typography>
              ) : (
                <Typography variant={"h3"} style={{ textAlign: "center" }}>
                  Számláid szinkronizálása 30 napra visszamenően éppen zajlik,
                  kis türelmedet kérjük! Amennyiben nem volt ilyen, új számláid
                  természetesen automatikusan szinkronizálódni fognak!
                </Typography>
              )}
            </Grid>

            {!szamlazzHu ||
              (!kulcsSoft && !count && !invoicesLoading && (
                <Grid
                  item
                  style={{
                    paddingBottom: "32px",
                    paddingLeft: "185px",
                    paddingRight: "185px"
                  }}
                >
                  <Typography fontSize={14} style={{ textAlign: "center" }}>
                    Első lépésként fel kell töltened azokat a számlákat amiket
                    faktorálásra szeretnél benyújtani, majd a számlákkal
                    kapcsolatos teendőkről és állapotokról a listában
                    tájékozódhatsz.
                  </Typography>
                </Grid>
              ))}
            {!(szamlazzHu || kulcsSoft) && !count && !invoicesLoading && (
              <Grid item>
                <Button
                  onClick={showUploadType}
                  backgroundColor={"#394AB8"}
                  style={{ fontSize: "12px" }}
                >
                  Feltöltöm az első számlám
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <InvoiceUploadTypeDialog
          open={uploadTypeShow}
          onSingleUploadClick={() => redirect("/app/invoice/new")}
          onMassUploadFileChanged={async file => {
            if (file) {
              setMassUploadFile(file);
              hideUploadType();
              showAgreementSelect();
            }
          }}
          onClose={hideUploadType}
        />
        <MassUploadAgreementSelectDialog
          open={agreementSelectShow}
          onClose={hideAgreementSelect}
          onAgreementSelected={async values => {
            await startUploadingMassInvoice(values.agreementId);
          }}
        />

        {/*<LoadingDialog loading={factorNotificationGenerating} />*/}
      </PageLayout>
    )
  );
};

export default withWidth()(InvoicePage);
