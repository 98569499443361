import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Field, Form, useField } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import {
  composeValidators,
  email,
  required,
  requiredWithCustomMessage
} from "../../../utils/validators";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "../../../FlexiComponents/FlexiLink";
import withCheckboxErrorMessage from "../../../utils/withCheckboxErrorMessage";
import PasswordField from "../../../components/PasswordField";
import { Fields } from "../../../FlexiComponents/FlexiForm";
import { useMutator } from "../../../hooks/useApi";
import { getFlexipayUrl } from "../../../utils/urlHelpers";
import { selectErrorNotificationMessage } from "../../../utils/selectErrorMessage";
import { useErrorOccurredNotification } from "../../../hooks/useNotificationState";

const blueCheckBoxStyles = {
  root: {
    "&$checked": {
      color: "#0045de"
    }
  },
  checked: {}
};

const ValidateTaxNumber = () => {
  const taxNumber = useField("taxNumber");
  const companyName = useField("companyName");
  const [post] = useMutator("POST");
  const notifyError = useErrorOccurredNotification();

  useEffect(() => {
    if (taxNumber.input.value.length === 13) {
      post(getFlexipayUrl("/register/search/company"), {
        taxNumber: taxNumber.input.value
      }).then(resp => {
        if (resp.error) {
          notifyError(selectErrorNotificationMessage(resp.error));
        } else {
          companyName.input.onChange(resp.data.companyName);
        }
      });
    } else {
      companyName.input.onChange(undefined);
    }
  }, [taxNumber.input.value]);

  return null;
};

const BlueCheckbox = withCheckboxErrorMessage(
  withStyles(blueCheckBoxStyles)(Checkbox)
);
const urlParams = new URLSearchParams(window.location.search);
const type = urlParams.get("type");
const polite = urlParams.get("polite");
const isCustomer = polite;
const RegisterForm = props => {
  const [showSelfEmployed, setShowSelfEmployed] = useState(false);
  const ButtonComponent = props.ButtonComponent || Button;

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant={"h5"}
            align={"center"}
            style={{ marginBottom: 10 }}
          >
            {isCustomer ? "Jelszó megadása" : "Regisztráció"}
          </Typography>
        </Grid>
      </Grid>
      <Form
        validate={values => {
          const errors = {};
          if (values?.plainPassword?.second !== values?.plainPassword?.first) {
            errors.plainPassword = {
              second: "A két jelszónak egyeznie kell !"
            };
          }

          return errors;
        }}
        onSubmit={props.onSubmit}
        initialValues={props.tokenData}
        render={({ form, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ValidateTaxNumber />
              <Grid
                container
                justify={"space-between"}
                style={{ marginBottom: "20px" }}
              >
                <Grid item style={{ width: "50%", paddingRight: "8px" }}>
                  <Field
                    name={"surname"}
                    label={"Vezetéknév*"}
                    component={TextField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: "50%", paddingLeft: "8px" }}>
                  <Field
                    name={"firstname"}
                    label={"Keresztnév*"}
                    component={TextField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: "20px" }}>
                <Grid item style={{ width: "100%", marginBottom: "20px" }}>
                  <Field
                    type={"email"}
                    name={"email"}
                    label={"E-mail cím*"}
                    component={TextField}
                    validate={composeValidators(email, required)}
                    fullWidth
                    disabled={props.tokenData?.email}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    name={"phoneNumber"}
                    label={"Telefonszám*"}
                    component={TextField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: "10px" }}>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    name={"plainPassword.first"}
                    label={"Jelszó*"}
                    component={PasswordField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: "10px" }}>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    name={"plainPassword.second"}
                    label={"Jelszó megerősítés*"}
                    component={PasswordField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {type !== "coworker" && !isCustomer && (
                <Grid container direction={"column"} alignItems={"flex-start"}>
                  <FormControlLabel
                    control={
                      <Field
                        name={"selfEmployed"}
                        component={BlueCheckbox}
                        onClick={e => {
                          form.change("companyName", undefined);
                          setShowSelfEmployed(e.target.checked);
                        }}
                        type={"checkbox"}
                      />
                    }
                    label={"Egyéni vállalkozó vagyok"}
                  />
                  {showSelfEmployed && (
                    <FormControlLabel
                      control={
                        <Field
                          name={"kata"}
                          type={"checkbox"}
                          component={BlueCheckbox}
                        />
                      }
                      label={"Kisadózó vagyok"}
                    />
                  )}
                  {!showSelfEmployed && (
                    <Field
                      style={{ marginTop: "-10px", marginBottom: "10px" }}
                      name={"companyName"}
                      label={"Vállalkozás neve"}
                      component={TextField}
                      validate={requiredWithCustomMessage(
                        "Töltsd ki az adószámod!"
                      )}
                      required
                      disabled
                      fullWidth
                    />
                  )}
                  <div style={{ marginBottom: "10px", width: "100%" }}>
                    <Fields
                      config={[
                        [
                          {
                            fieldType: "taxNumber",
                            name: "taxNumber",
                            required: true,
                            label: "Adószám"
                          }
                        ]
                      ]}
                    />
                  </div>
                </Grid>
              )}
              <Grid
                container
                direction={"column"}
                alignSelf={"flex-start"}
                style={{ textAlign: "left" }}
              >
                <Fields
                  ContainerGridProps={{ spacing: 0 }}
                  config={[
                    [
                      {
                        fieldType: "checkboxWithDialog",
                        title: "Felhasználási feltételek",
                        name: "acceptTermsAndConditions",
                        required: true,
                        label: (
                          <Typography variant={"body1"}>
                            Elfogadom a{" "}
                            <Link
                              style={{
                                fontWeight: "bold",
                                textDecoration: "none",
                                color: "#02BE6F"
                              }}
                              href={
                                "https://storage.googleapis.com/flexibill-documents/documents/20220201_Flexibill_Digitalis_Faktoring_ASZF.pdf"
                              }
                              target="_blank"
                            >
                              felhasználási feltételeket.
                            </Link>
                          </Typography>
                        ),
                        url:
                          "https://storage.googleapis.com/flexibill-documents/documents/20220201_Flexibill_Digitalis_Faktoring_ASZF.pdf"
                      }
                    ],
                    [
                      {
                        fieldType: "checkboxWithDialog",
                        title: "Adatvédelmi tájékoztató",
                        name: "privacy",
                        required: true,
                        label: (
                          <Typography variant={"body1"}>
                            Elfogadom az{" "}
                            <Link
                              style={{
                                fontWeight: "bold",
                                textDecoration: "none",
                                color: "#02BE6F"
                              }}
                              href={
                                "https://storage.googleapis.com/flexibill-documents/documents/adatvedelmi_tajekoztato_flexi.pdf"
                              }
                              target="_blank"
                            >
                              adatkezelési tájékoztatót.
                            </Link>
                          </Typography>
                        ),
                        url:
                          "https://storage.googleapis.com/flexibill-documents/documents/adatvedelmi_tajekoztato_flexi.pdf"
                      }
                    ]
                  ]}
                />
                <Grid item xs={12} style={{ alignSelf: "center" }}>
                  <ButtonComponent
                    type={"submit"}
                    style={{ marginTop: "20px" }}
                  >
                    {isCustomer ? "Mentés" : "Regisztrálok"}
                  </ButtonComponent>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </div>
  );
};

export default RegisterForm;
