import PropTypes from "prop-types";
import FlexiRemotePager from "../FlexiRemotePager";
import FlexiExpansionPanel from "../FlexiExpansionPanel";
import FlexiListRemote from "../FlexiList/Remote";
import download from "downloadjs";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import BulkActions from "./BulkActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import FlexiInfoBar from "../FlexiInfoBar/index.js";
import FlexiAdvancedFilter, {
  resetStoredFilterData,
} from "../FlexiAdvancedFilter";
import useFetch from "../utils/useFetch";
import DownloadDialog from "./DownloadDialog";
import { breakpoint } from "../utils/breakpoints";
import makeLongTextColumnDefinitionConfig from "./helpers/makeLongTextColumnDefinitionConfig";
import FlexiList from "../FlexiList";

const hashCode = (s) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
const LAST_PAGER_DATA_KEY = "flexiRemoteExpandableListPagination-";
const resetListStoredData = () => {
  resetStoredFilterData();
  Object.keys(window.sessionStorage)
    .filter((key) => key.startsWith(LAST_PAGER_DATA_KEY))
    .forEach((key) => window.sessionStorage.removeItem(key));
};

export { makeLongTextColumnDefinitionConfig, resetListStoredData };

export const FlexiExpandableRemoteListContext = React.createContext({
  token: null,
  companyId: null,
});

const ExpansionVisibilityWrapper = styled.div`
  & > div > div > div:first-child {
    ${(props) => props.suppressExpand && "display: none;"};
  }

  ${breakpoint("lg")`

    .yellow {
      background-color: #fbf4e7 !important;
    }
    
    .red  {
      background-color: #F2E3E5 !important;
    }
  
  `} ${breakpoint("xs", "lg")`

    .ag-react-container {
      padding-bottom: 5px;
    }

  `}

  .yellow .ag-react-container > div {
    background-color: #fbf4e7 !important;
  }
  .red .ag-react-container > div {
    background-color: #f2e3e5 !important;
  }

  .ag-body-horizontal-scroll-viewport {
    display: none;
  }
`;

// let agGridParams;

const FlexiExpandableRemoteList = forwardRef(
  (
    {
      url,
      title,
      columnDefs,
      hideSearchBar,
      hidePager,
      rowsPerPageOptions,
      onError = (e) =>
        console.warn(
          "Error occured, but onError handler missing in FlexiExpandableRemoteList",
          e
        ),
      onLoadingChange,
      bulkActions,
      itemName,
      FlexiInfoBarProps,
      gridOptions,
      suppressExpand,
      advancedFilterEnabled,
      advancedFilterAdditionalOptions = [],
      advancedFilterGroupsOrderConfig,
      advancedFilterExportUrl,
      advancedFilterDownloadUrl,
      DownloadDialogProps = {
        title: "Számlák letöltése",
        downloadButtonText: "Letöltés",
        cancelButtonText: "Mégse",
        beforeDownloadDescription: "A számlák letöltése eltarthat egy ideig.",
        downloadingDescription: "A számlák letöltése folyamatban van.",
      },
      onSelectionChanged,
      simpleSearchKey = "search",
      EmptyListProps,
      localItems,
      renderAboveTheList,
    },
    ref
  ) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentQueryString, setCurrentQueryString] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [downloadPopupShow, setDownloadPopupShow] = useState(false);
    const [advFilterKeyValues, setAdvFilterKeyValues] = useState({});
    const [agGridParams, setAgGridParams] = useState(null);
    const context = useContext(FlexiExpandableRemoteListContext);
    const fileFetch = useFetch({ token: context.token, blob: true });
    const paginationDataKey = useMemo(
      () => `${LAST_PAGER_DATA_KEY}${hashCode(JSON.stringify(columnDefs))}`,
      []
    );

    const emptyList =
      !loading &&
      total === 0 &&
      !Object.keys(advFilterKeyValues).length &&
      (!localItems || !localItems.length);

    useEffect(() => {
      if (onSelectionChanged) {
        onSelectionChanged(selectedRows);
      }
    }, [selectedRows]);

    const deselectAll = useCallback(() => {
      setSelectedRows([]);
      if (agGridParams) {
        agGridParams.api.deselectAll();
      }
    }, [agGridParams]);

    const downloadSelectedInvoices = useCallback(
      (url, fileName) => {
        setDownloading(true);
        const body = {
          entities: selectedRows.length
            ? selectedRows.map((item) => item.id)
            : "all",
        };

        const urlWithQueryString =
          currentQueryString && currentQueryString.length
            ? `${url}?${currentQueryString}`
            : url;

        fileFetch(getUrlWithCompanyId(urlWithQueryString), {
          method: "POST",
          body: JSON.stringify(body),
        })
          .then((resp) => {
            download(resp, fileName);
            deselectAll();
          })
          .catch(onError)
          .finally(() => {
            setDownloading(false);
            setDownloadPopupShow(false);
          });
      },
      [selectedRows, currentQueryString]
    );

    const downloadExport = useCallback(
      () =>
        downloadSelectedInvoices(
          getUrlWithCompanyId(advancedFilterExportUrl),
          "export.zip"
        ),
      [downloadSelectedInvoices]
    );

    const downloadDownload = useCallback(
      () =>
        downloadSelectedInvoices(advancedFilterDownloadUrl, "dokumentumok.zip"),
      [downloadSelectedInvoices]
    );

    const getUrlWithCompanyId = (url) =>
      context.companyId ? url.replace("{companyId}", context.companyId) : url;

    const pagerRef = useRef();
    const expansionRef = useRef();

    const advancedFiltersWithAdditionalOptions = useMemo(() => {
      return [
        ...columnDefs,
        ...advancedFilterAdditionalOptions.map((item) => ({
          filterConfig: item,
        })),
      ];
    }, [advancedFilterAdditionalOptions, columnDefs]);

    const columnDefsWithCheckboxSelection = useMemo(() => {
      const selectionCol = columnDefs.find((item) => item.checkboxSelection);

      if (selectionCol) {
        if (selectionCol.minWidth) {
          selectionCol.minWidth = parseInt(selectionCol.minWidth, 10) + 40;
        }
        return columnDefs;
      }

      if (
        advancedFilterEnabled &&
        (advancedFilterExportUrl || advancedFilterDownloadUrl)
      ) {
        const index = columnDefs[0].field === "status" ? 1 : 0;

        columnDefs[index].checkboxSelection = true;
        columnDefs[index].headerCheckboxSelection = true;

        if (columnDefs[index].minWidth) {
          columnDefs[index].minWidth =
            parseInt(columnDefs[index].minWidth, 10) + 40;
        }
      }

      return columnDefs;
    }, [columnDefs]);

    useImperativeHandle(ref, () => ({
      open: expansionRef.current && expansionRef.current.open,
      reload: pagerRef.current && pagerRef.current.reload,
      selectedRows,
      deselectAll,
      downloadExport,
      downloadDownload,
      total,
      loading,
      agGridParams,
    }));

    useEffect(() => {
      if (agGridParams) {
        setTimeout(() => {
          agGridParams.api.sizeColumnsToFit();
        }, 10);
      }
    }, [columnDefs && columnDefs.length, agGridParams]);

    return localItems !== undefined ? (
      <FlexiList
        hidePager={hidePager}
        hideSearchBar={hideSearchBar}
        columnDefs={columnDefs}
        items={localItems}
        onGridReady={(params) => {
          setAgGridParams(params);
          params.api.sizeColumnsToFit();
        }}
        onColumnResized={(params) => params.api.resetRowHeights()}
        getGridOptions={(opt) => ({
          ...opt,
          rowSelection: "multiple",
          suppressMovableColumns: false,
          suppressRowClickSelection: true,
          defaultColDef: {
            resizable: true,
          },
          onSelectionChanged: ({ api }) => {
            setSelectedRows(api.getSelectedNodes().map((node) => node.data));
          },
          ...gridOptions,
        })}
        EmptyListProps={EmptyListProps}
      />
    ) : (
      <FlexiRemotePager
        ref={pagerRef}
        token={context.token}
        url={getUrlWithCompanyId(url)}
        onError={onError}
        onLoadingChange={(loading) => console.log(loading)}
        extraQueryParams={advFilterKeyValues}
        simpleSearchKey={simpleSearchKey}
        rowsPerPageOptions={rowsPerPageOptions}
        storePaginationDataKey={paginationDataKey}
      >
        {({
          items,
          total,
          loading,
          onPaginationChange,
          queryString,
          currentPagination,
        }) => {
          setTotal(total);
          setLoading(loading);
          setCurrentQueryString(queryString);
          if (!suppressExpand) {
            if (expansionRef.current) {
              if (!total) {
                expansionRef.current.close();
              } else {
                expansionRef.current.open();
              }
            }
          }

          return (
            <ExpansionVisibilityWrapper suppressExpand={suppressExpand}>
              <FlexiExpansionPanel
                title={title}
                count={total}
                ref={expansionRef}
                defaultExpanded={suppressExpand}
              >
                <Grid container spacing={2}>
                  {advancedFilterEnabled && !emptyList && (
                    <Grid item xs={12}>
                      <FlexiAdvancedFilter
                        columnDefs={advancedFiltersWithAdditionalOptions}
                        groupsOrderConfig={advancedFilterGroupsOrderConfig}
                        onFilter={setAdvFilterKeyValues}
                        onExport={advancedFilterExportUrl && downloadExport}
                        onDownload={
                          advancedFilterDownloadUrl &&
                          (() => setDownloadPopupShow(true))
                        }
                      />
                    </Grid>
                  )}
                  {renderAboveTheList && (
                    <Grid item xs={12}>
                      {renderAboveTheList()}
                    </Grid>
                  )}
                  {loading && (
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <LinearProgress
                        style={{ position: "absolute", width: "100%", top: 0 }}
                      />
                    </Grid>
                  )}

                  {FlexiInfoBarProps && (
                    <Grid item xs={12}>
                      <FlexiInfoBar {...FlexiInfoBarProps} />
                    </Grid>
                  )}

                  {Boolean(bulkActions) && !emptyList && (
                    <Grid item xs={12}>
                      <BulkActions
                        itemName={itemName}
                        selectedItems={selectedRows}
                        actions={
                          typeof bulkActions === "function"
                            ? bulkActions(advFilterKeyValues)
                            : bulkActions
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FlexiListRemote
                      items={items}
                      totalCount={total}
                      columnDefs={columnDefsWithCheckboxSelection}
                      getGridOptions={(opt) => ({
                        ...opt,
                        rowSelection: "multiple",
                        suppressMovableColumns: false,
                        suppressRowClickSelection: true,
                        defaultColDef: {
                          resizable: true,
                        },
                        onSelectionChanged: ({ api }) => {
                          setSelectedRows(
                            api.getSelectedNodes().map((node) => node.data)
                          );
                        },
                        ...gridOptions,
                      })}
                      onGridReady={(params) => {
                        setAgGridParams(params);
                        window.onresize = () => {
                          params.api.sizeColumnsToFit();
                        };
                        params.api.sizeColumnsToFit();
                      }}
                      onPageChange={(...args) => {
                        onPaginationChange(...args);
                        deselectAll();
                      }}
                      hidePager={
                        !Boolean(items.length) || hidePager || emptyList
                      }
                      hideSearchBar={
                        advancedFilterEnabled || hideSearchBar || emptyList
                      }
                      rowsPerPageOptions={rowsPerPageOptions}
                      EmptyListProps={
                        Object.keys(advFilterKeyValues).length
                          ? {}
                          : EmptyListProps
                      }
                      loading={loading}
                      currentPagination={currentPagination}
                    />
                  </Grid>
                </Grid>
              </FlexiExpansionPanel>
              <DownloadDialog
                open={downloadPopupShow}
                onClose={() => setDownloadPopupShow(false)}
                downloading={downloading}
                onDownloadClick={downloadDownload}
              />
            </ExpansionVisibilityWrapper>
          );
        }}
      </FlexiRemotePager>
    );
  }
);

FlexiExpandableRemoteList.displayName = "FlexiExpandableRemoteList";

export default FlexiExpandableRemoteList;

FlexiExpandableRemoteList.propTypes = {
  FlexiInfoBarProps: PropTypes.object,
  bulkActions: PropTypes.array,
  columnDefs: PropTypes.array,
  onError: PropTypes.func,
  onLoadingChange: PropTypes.func,
  title: PropTypes.string,
  token: PropTypes.string,
  url: PropTypes.string,
  hideSearchBar: PropTypes.bool,
  hidePager: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  suppressExpand: PropTypes.bool,
  advancedFilterEnabled: PropTypes.bool,
  advancedFilterAdditionalOptions: PropTypes.array,
  advancedFilterGroupsOrderConfig: PropTypes.object,
  advancedFilterExportUrl: PropTypes.string,
  advancedFilterDownloadUrl: PropTypes.string,
  DownloadDialogProps: PropTypes.shape({
    title: PropTypes.string,
    downloadButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    beforeDownloadDescription: PropTypes.string,
    downloadingDescription: PropTypes.string,
  }),
  EmptyListProps: PropTypes.shape({
    titleText: PropTypes.string,
    descriptionText: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  localItems: PropTypes.array,
};
