import React, { useState } from "react";
import FlexiPageLayout from "../../FlexiComponents/FlexiPageLayout";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { getStoredToken } from "../../utils/authentication";
import FlexiExpandableRemoteList, {
  makeLongTextColumnDefinitionConfig
} from "../../FlexiComponents/FlexiExpandableRemoteList";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import infoIcon from "../../images/redesignImages/Ikon (2).svg";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_INVOICE_MANAGER
} from "../../roles";
import { makeStatusIconRenderer } from "../InvoicePage";
import { useIntl } from "react-intl";
import { Link } from "@material-ui/core";
import StatusDescriptionDialog from "../../components/StatusDescriptionDialog";
import { InvoiceStatusIcon } from "../InvoicePage/InvoiceStatusIcons";
import CustomerWelcomeHeader from "./CustomerWelcomeHeader";
import Button from "@material-ui/core/Button";

const statuses = [
  {
    icon: <InvoiceStatusIcon status={"APPROVED"} />,
    value: "APPROVED",
    text: "Elfogadva",
    description:
      "A számlákat visszaigazolta és amennyiben szükséges, aláírta a dokumentumot."
  },
  {
    icon: <InvoiceStatusIcon status={"ON_HOLD"} />,
    value: "ON_HOLD",
    text: "Várakoztatva",
    description: "A számlákat még vissza kell igazolnia."
  },
  {
    icon: <InvoiceStatusIcon status={"REJECTED"} />,
    value: "REJECTED",
    text: "Elutasítva",
    description: "A számlacsoportban lévő számlák mindegyikét elutasította."
  },
  {
    icon: <InvoiceStatusIcon status={"WAITING_FOR_CUSTOMER_CONFIRMATION"} />,
    value: "WAITING_FOR_CUSTOMER_CONFIRMATION",
    text: "Vevő visszaigazolásra vár",
    description: "A számlák valódiságát Önnek még vissza kell igazolnia."
  },
  {
    icon: <InvoiceStatusIcon status={"WAITING_FOR_CUSTOMER_SIGNATURE"} />,
    value: "WAITING_FOR_CUSTOMER_SIGNATURE",
    text: "Vevő aláírásra vár",
    description: "Alá kell írnia a faktorálási értesítő tudomásulvételét."
  }
];

const CustomerInvoiceGroupsPage = () => {
  const intl = useIntl();
  const { push: redirect } = useHistory();
  const [statusHelperOpen, setStatusHelperOpen] = useState(false);

  return (
    <>
      <CustomerWelcomeHeader />
      <FlexiPageLayout title={"Számlacsoportok"}>
        <FlexiExpandableRemoteList
          renderAboveTheList={() => (
            <Box display={"flex"} justifyContent={"space-between"}>
              <>
                <Typography fontSize={18} variant={"h4"}>
                  Összes számlacsoport
                </Typography>
              </>
              <div style={{ display: "inline-flex" }}>
                <img
                  src={infoIcon}
                  style={{ width: "20px", paddingRight: "10px" }}
                />
                <Link
                  onClick={() => setStatusHelperOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant={"body1"} style={{ lineHeight: "30px" }}>
                    Státusz magyarázat
                  </Typography>
                </Link>
              </div>
            </Box>
          )}
          advancedFilterEnabled
          gridOptions={{
            rowSelection: "multiple",
            suppressMovableColumns: false,
            suppressRowClickSelection: true,
            defaultColDef: {
              resizable: true
            },
            rowClassRules: {
              yellow: params => {
                return params.data.isWaitingForCustomerInteract;
              }
            },
            onRowClicked: ({ data }) =>
              redirect(`/app/customer-invoice-groups/${data?.id}`),
            rowStyle: { cursor: "pointer" }
          }}
          hideSearchBar
          suppressExpand
          // ref={listRefs[2]}
          url={getUrlWithCompanyId("/customer/invoice-group/")}
          token={getStoredToken()}
          rowsPerPageOptions={[25]}
          showMoreLimit={25}
          columnDefs={[
            {
              headerName: "Szállító neve",
              headerClass: "left-header",
              field: "supplierName",
              sortable: false,
              cellClass: "cell-wrap-text multi-line padding-top left-cell",
              filterConfig: {
                type: "text",
                collapsed: false,
                order: 1
              },
              width: 340,
              ...makeLongTextColumnDefinitionConfig({
                tooltip: true,
                lines: 2
              })
            },
            {
              headerName: "Számlacsoport",
              // minWidth: 120,
              flex: 1,
              field: "faktoriId",
              filterConfig: {
                type: "text",
                filterParam: "invoiceGroup",
                collapsed: false,
                order: 2
              },
              sortable: false,
              cellRendererFramework: params => {
                return (
                  <span style={{ display: "inline-flex" }}>
                    <Typography>{params.value}</Typography>
                    {params.data.hasDueInvoice && (
                      <Tooltip
                        title={params.data.hasDueInvoice ? "Lejárt számla" : ""}
                        placement={"bottom"}
                      >
                        <AnnouncementIcon
                          style={{
                            marginLeft: "5px",
                            fill: "#D0021B",
                            fontSize: 21
                          }}
                        />
                      </Tooltip>
                    )}
                  </span>
                );
              }
            },
            {
              headerName: "Számlák",
              // minWidth: 100,
              flex: 1,
              field: "invoicesCount",
              autoHeight: true,
              cellClass: "cell-wrap-text",
              filterConfig: {
                type: "text",
                collapsed: false,
                filterParam: "invoiceNumber",
                order: 3
              }
            },
            {
              headerName: "Visszaigazolt",
              // minWidth: 100,
              flex: 1,
              field: "confirmedInvoicesCount",
              autoHeight: true,
              cellClass: "cell-wrap-text"
            },
            {
              headerName: "Számlák összege",
              // minWidth: 120,
              flex: 2,
              field: "totalGrossAmountForCustomer",
              autoHeight: true,
              cellClass: "cell-wrap-text",
              filterConfig: {
                collapsed: true,
                type: "text",
                range: true
              },
              valueFormatter: ({ value, data }) =>
                makeCurrencyValueFormatter(intl)({
                  value,
                  data: { ...data, currency: data?.getTotalGrossAmountCurrency }
                })
            },
            {
              headerName: "Státusz",
              field: "statusForCustomer",
              sortable: true,
              autoHeight: true,
              cellClass: "cell-wrap-text",
              cellRendererFramework: makeStatusIconRenderer(),
              flex: 1,
              filterConfig: {
                type: "select",
                options: statuses,
                collapsed: false,
                filterParam: "status",
                order: 4
              }
            },
            {
              headerName: "",

              roles: [
                ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
                ROLE_FLEXIPAY_INVOICE_MANAGER
              ],
              cellRendererFramework: item => {
                return (
                  <span>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        redirect(
                          `/app/customer-invoice-groups/${item?.data?.id}`
                        )
                      }
                    >
                      Részletek
                    </Button>
                  </span>
                );
              }
            }
          ]}
        />
        <StatusDescriptionDialog
          open={statusHelperOpen}
          onClose={() => setStatusHelperOpen(false)}
          title={"Státusz magyarázat"}
          statuses={statuses}
        />
      </FlexiPageLayout>
    </>
  );
};

export default CustomerInvoiceGroupsPage;
