import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import privateRoutes from "./privateRoutes";
import FlexiLayout from "../../../src/FlexiComponents/FlexiLayout";
import React, { useEffect, useMemo, useState } from "react";
import payLogo from "../../images/flexipaybill_hor_white.svg";
import payLogoSzamlazzHu from "../../images/szamlazzhu/szlahu2/flexi_szlahu_white.svg";
import payLogoKulcsSoft from "../../images/kulcsSoft/flexi_kulcslostLogo.svg";
import robotImg from "../../images/robot.svg";
import billAvatar from "../../images/menuBill.svg";
import waterMarkLogo from "../../images/fexibill_digitalis_faktoring_feher.svg";

import {
  getStoredCompanyId,
  getStoredToken,
  storeCompanyId
} from "../../utils/authentication";
import ReactTour from "../../components/Tour";
import useTourState from "../../hooks/useTourState";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { useProfileData } from "../../hooks/useApi";
import { getConfig } from "../../utils/config";
import RoleProvider from "../../components/RoleProvider";
import {
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER,
  ROLE_FLEXIPAY_CUSTOMER,
  ROLE_FLEXIPAY_CUSTOMER_PLUS,
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_SALES_ADMIN
} from "../../roles";
import AccountMatchingDialog from "../../components/AccountMatchingDialog";
import arrowRight from "../../images/redesignImages/Arrow 1.svg";
import { FlexiExpandableRemoteListContext } from "../../FlexiComponents/FlexiExpandableRemoteList";
import GreetingMessageDialog from "./GreetingMessageDialog";
import FlexiFileUploaderProvider from "../../components/FlexiFileUploadProvider";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import CoWelcomeDialog from "../../components/CoWelcomeDialog";

const homeLogoUrl = "/";
const logoProps = {
  src: payLogo,
  style: {
    width: "auto"
  },
  alt: "FlexiPay",
  title: "FlexiPay ®"
};

const Wrapper = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.up("lg")]: {
        paddingTop: "60px"
      }
    }
  };
})(Box);

const InnerApplication = ({ width, history }) => {
  const {
    mainCompany,
    companies,
    user,
    loading,
    szamlazzHu,
    kulcsSoft
  } = useProfileData();
  const [tourState, tourActions] = useTourState();

  if (szamlazzHu) {
    logoProps.src = payLogoSzamlazzHu;
  }
  if (kulcsSoft) {
    logoProps.src = payLogoKulcsSoft;
  }

  const routes = useMemo(() => {
    if (mainCompany) {
      if (mainCompany.roles.includes(ROLE_FLEXIPAY_CUSTOMER_PLUS)) {
        return privateRoutes.filter(route => route.showOnFlexipayCustomerPlus);
      } else if (mainCompany.roles.includes(ROLE_FLEXIPAY_CUSTOMER)) {
        return privateRoutes.filter(route => route.showOnFlexipayCustomer);
      } else if (mainCompany.roles.includes(ROLE_FLEXIPAY_SALES_ADMIN)) {
        return privateRoutes.filter(route => route.showOnFlexipaySales);
      } else if (
        mainCompany.roles?.length === 1 &&
        mainCompany.roles[0] === ROLE_FLEXIPAY_AGREEMENT_UPLOADER
      ) {
      } else if (mainCompany.roles.includes(ROLE_FLEXIPAY_INVOICE_MANAGER)) {
        return privateRoutes.filter(
          route => route.showOnInvoiceManager !== false
        );
      } else if (mainCompany?.flexipayOnboardingStatus !== "COMPLETED") {
        return privateRoutes.filter(route => route.showOnNewContract);
      } else if (szamlazzHu) {
        return privateRoutes.filter(route => !route.hideOnSzamlazzHu);
      } else if (kulcsSoft) {
        return privateRoutes.filter(route => !route.hideOnKulcsSoft);
      }
      return privateRoutes.filter(
        route => !route.path.includes("customer-invoice-groups")
      );
    } else {
      return [];
    }
  }, [mainCompany]);

  const navBarProps = useMemo(() => {
    return {
      onNavListItemClick: () => undefined,
      menuItems: routes.filter(route => route.menuItem)
    };
  }, [routes]);

  useEffect(() => {
    if (!mainCompany?.roles.includes(ROLE_FLEXIPAY_CUSTOMER)) {
      switch (mainCompany?.flexipayOnboardingStatus) {
        case "NEW":
          if (!window.location.href.includes("new-contract")) {
            history.push("/app/agreement-claims/new-contract");
          }
          break;
        case "IN_PROGRESS":
          if (!window.location.href.includes("agreement-claims")) {
            history.push("/app/agreement-claims");
          }
          break;

        default:
          break;
      }
    }
  }, [mainCompany?.flexipayOnboardingStatus]);

  const companyChooserProps = {
    avatarSrc: robotImg,
    appbarcomponent: !isWidthDown("sm", width),
    companies: [],
    menuItems: [],
    company: "",
    username: "",
    appsIconShow: !isWidthDown("sm", width),
    appsIconColor: "#ffffff",
    onCompanySelected: item => {
      if (item && item.id) {
        if (item.id === "LOGOUT") {
          history.push("/app/logout");
        } else {
          storeCompanyId(item.id);
          window.location.href = "/";
        }
      }
    }
  };

  if (mainCompany) {
    companyChooserProps.company = mainCompany?.party?.partyName;
    companyChooserProps.logoPath = mainCompany?.profilePicture?.path;
  }

  if (user) {
    companyChooserProps.username = user.email;
  }

  companyChooserProps.companies = [
    ...companies,
    {
      name: "Kijelentkezés",
      id: "LOGOUT",
      border: false,
      hideOnMobile: true,
      icon: <img alt={"arrowRight"} src={arrowRight} />
    }
  ];

  const layoutDemoProps = {
    companyChooserProps,
    navBarProps,
    homeLogoUrl,
    logoProps,
    sidebarbgcolor: "#142595",
    appbarbgcolor: "#000B55"
  };

  const flexiPayUrl = `${getConfig(
    "FLEXIBILL_APP_URL"
  )}/auth/token/${getStoredToken()}/company-id/${getStoredCompanyId()}`;

  const otherInterface =
    !loading && mainCompany && !mainCompany?.roles?.length && !szamlazzHu;
  const accountSuspended = !loading && mainCompany?.active === false;
  const noCompany = !loading && !mainCompany;

  const [token, setToken] = useState(getStoredToken());

  useEffect(() => {
    const setAndCheckToken = () => {
      setTimeout(() => {
        const tokenFromLocalStorage = getStoredToken();
        if (!tokenFromLocalStorage) {
          setAndCheckToken();
        } else {
          setToken(tokenFromLocalStorage);
        }
      }, 1000);
    };

    setAndCheckToken();
  }, []);

  const salesProps2 = {
    flexipayContactList: [
      {
        department: "sales",
        name: "Test Admin Sales 1",
        email: "flexipay_test_sales1@test.com",
        phoneNumber: "+123445678"
      }
      // {
      //   department: "backoffice",
      //   name: "Test Admin Sales 2",
      //   email: "flexipay_test_sales1@test.com",
      //   phoneNumber: "+993445678",
      //
      // },
    ]
  };

  const salesData = mainCompany?.flexipayContactList;
  const salesProps = {
    placeholderImg: billAvatar,
    isSalesDepartment: salesData?.length === 1,
    salesContact: salesData?.[0],
    invoiceHelp: salesData?.[0],
    contractHelp: salesData?.[1]
  };
  const [coWelcomeDialogOpen, setCoWelcomeDialogOpen] = useState(false);

  useEffect(() => {
    if (
      szamlazzHu &&
      mainCompany &&
      !mainCompany.party.contact.telephone &&
      mainCompany?.flexipayOnboardingStatus !== "COMPLETED" &&
      !localStorage.getItem("szamlazzHuWelcomePopupShown")
    ) {
      setCoWelcomeDialogOpen(true);
    }
  }, [szamlazzHu, mainCompany]);

  return (
    <RoleProvider roles={mainCompany?.roles}>
      <FlexiExpandableRemoteListContext.Provider value={{ token }}>
        <FlexiFileUploaderProvider token={token}>
          <CoWelcomeDialog
            open={coWelcomeDialogOpen}
            onClose={() => {
              setCoWelcomeDialogOpen(false);
              localStorage.setItem("szamlazzHuWelcomePopupShown", true);
            }}
          />
          {/*<OnHolidayDialog />*/}
          <AccountMatchingDialog
            otherInterface={otherInterface}
            accountSuspended={accountSuspended}
            noCompany={noCompany}
            // onClose={() => setAccountMatchingDialogOpen(null)}
          />
          <GreetingMessageDialog />
          <FlexiLayout
            salesProps={salesData ? salesProps : undefined}
            waterMark
            waterMarkSrc={waterMarkLogo}
            navBarProps={navBarProps}
            {...layoutDemoProps}
            beta={getConfig("APP_ENV") !== "production"}
            // renderAppBarComponents={() => (
            //   <div style={{ alignSelf: "center" }}>
            //     <FlexiPlatformChangerButton
            //       text="Váltás FlexiBill-re"
            //       logoPath={billLogo}
            //       onButtonClick={() => window.open(flexiPayUrl)}
            //     />
            //   </div>
            // )}
          >
            <Wrapper>
              <Switch>
                {routes.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                  />
                ))}
                {Boolean(navBarProps.menuItems.length) && (
                  <Redirect to={navBarProps.menuItems[0].path} />
                )}
              </Switch>
            </Wrapper>
            <ReactTour
              isOpen={tourState.isTourOpen}
              onRequestClose={() => tourActions.toggleTourOpen()}
            />
          </FlexiLayout>
        </FlexiFileUploaderProvider>
      </FlexiExpandableRemoteListContext.Provider>
    </RoleProvider>
  );
};

export default withRouter(withWidth()(InnerApplication));
