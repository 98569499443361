import React, { useEffect, useMemo, useState } from "react";
import { defineMessages } from "react-intl.macro";
import { useCustomerInvoiceGroupPageData } from "../../hooks/useApi";
import CustomerConfirmationList from "./CustomerConfirmationList";
import Typography from "@material-ui/core/Typography";
import DocumentsCarousel from "./DocumentsCarousel";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import FlexiCustomerDialog from "../../components/FlexiCustomerDialog";
import usePopupState from "../../hooks/usePopupState";
import { useIntl } from "react-intl";
import FlexiInfoBar from "../../FlexiComponents/FlexiInfoBar";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import Grid from "@material-ui/core/Grid";
import billSmile from "../../images/bill/bill_vigyor_80px.svg";
import { useHistory, Prompt } from "react-router-dom";
import { mutate } from "swr";
import Chip from "@material-ui/core/Chip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { isWidthUp } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withWidth from "@material-ui/core/withWidth";
import {
  FactorNotificationPageContent,
  Header
} from "../FactorNotificationPage";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import FlexiLink from "../../FlexiComponents/FlexiLink";
import ConfirmationDialog from "./ConfirmDialog";
import RejectReasonDialog from "./RejectReasonDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import FlexiPageLayout from "../../FlexiComponents/FlexiPageLayout";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import ConfirmationHelpDialog from "../../components/CutomerOnboarding/ConfirmationHelpDialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationsIcon from "@material-ui/icons/Notifications";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.customerConfirmationPage",
    defaultMessage: "Számlák"
  },
  groupAttachmentsDialogTitle: {
    id: "dialogTitle.groupAttachmentsDialog",
    defaultMessage: "Számlacsoporthoz tartozó dokumentumok"
  },
  invoiceAttachmentsDialogTitle: {
    id: "dialogTitle.invoiceAttachmentsDialogTitle",
    defaultMessage: "Számlához tartozó dokumentumok"
  },
  showInvoiceGroupAttachments: {
    id: "text.showInvoiceGroupAttachments",
    defaultMessage: "Számlacsoporthoz tartozó dokumentumok"
  },
  customerInterface: {
    id: "chip.customerInterface",
    defaultMessage: "VEVŐI FELÜLET"
  },
  contactPartner: {
    id: "text.contactPartner",
    defaultMessage: "KAPCSOLAT FELVÉTELE A PARTNERREL"
  },
  contactEmail: {
    id: "text.contactEmail",
    defaultMessage: "E-mail cím:"
  },
  contactTel: {
    id: "text.contactTel",
    defaultMessage: "Telefonszám:"
  },
  infobarText: {
    id: "text.infobarText",
    defaultMessage: `Kérem igazolja vissza az oldalon található számlákat! Ezt követően írhatja alá a faktorálási értesítőtudomásulvételét a \"Tovább\" gombra kattintva!`
  }
});

const CustomerChip = withStyles({
  root: {
    backgroundColor: "#fff"
  }
})(Chip);

const urlParams = new URLSearchParams(window.location.search);
const isSignGetParam = urlParams.get("sign");
const beforeunload = function(e) {
  var confirmationMessage =
    "Az összes számla visszaigazolása után, a Mentés gombra kattintva fejezheti be a folyamatot. Ha még nem érkezett meg minden számla, érdemes azokat várakoztatni, így az elfogadott számlák faktorálása folytatódhat. ";

  (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
};

const actionHelper = [
  {
    text: "Módosítás",
    description:
      "Itt lehet megadni, ha a számla összegéből szeretne beszámítást levonni, illetve megadhatja, hogy mikor várható a számla tényleges kifizetése.",
    icon: "kéne"
  },
  {
    text: "Elfogadás",
    description:
      "A számla a valóságnak megfelel és minden rendben van vele. Kifizetését tervezi a vállalat.",
    icon: "kéne"
  },
  {
    text: "Elutasítás",
    description:
      "A számlával valami probléma van, így ebben a formában nem tervezi kifizetni.",
    icon: "kéne"
  },
  {
    text: "Várakoztatás",
    description:
      "A számlával kapcsolatban nincs elég információja a visszajelzéshez, így később szeretne válaszolni. A számla visszaigazolásával kapcsolatban a Flexibill később újra emlékeztetőt fog küldeni Önnek",
    icon: "kéne"
  }
];

const GreenButton = withStyles({
  root: {
    background: "#8BC34A !important",
    "&:hover": { background: "#6a923c !important" }
  }
})(Button);

const ListSection = ({
  title,
  loading,
  rightSideComponents,
  faktoriId,
  invoiceGroup,
  reloadInvoiceGroup,
  guestFetch,
  getGroupDocumentUrl,
  actions,
  completed,
  switchView,
  readOnly
}) => {
  const intl = useIntl();
  const [showDocumentsInvoice, setShowDocumentsInvoice] = useState(null);
  const [invoiceToReject, setInvoiceToReject] = useState(null);
  const history = useHistory();

  const [
    attachmentsDialogOpen,
    showAttachmentsDialog,
    hideAttachmentsDialog
  ] = usePopupState("CustomerConfirmationAttachmentsDialog");

  const makeInvoiceActionHandler = action => async invoice => {
    const { data, error } = await action(invoice);
    if (data) {
      await reloadInvoiceGroup();
    }
  };

  const [actionHelperOpen, setActionHelperOpen] = useState(false);

  return (
    <FlexiPageLayout
      title={title}
      loading={loading}
      rightSideComponents={rightSideComponents}
      rightSideComponentsPlacement={"bottom"}
      backButton
      onBackButtonClick={() => history.push("/app/customer-invoice-groups")}
    >
      <RejectReasonDialog
        open={Boolean(invoiceToReject)}
        onClose={() => setInvoiceToReject(null)}
        onSubmit={values =>
          makeInvoiceActionHandler(actions.reject)({
            id: invoiceToReject.id,
            rejectInfo: values
          }).then(() => setInvoiceToReject(null))
        }
      />

      <Header
        id={invoiceGroup?.id}
        groupId={
          <>
            {invoiceGroup?.faktoriId}
            {Boolean(invoiceGroup?.documents?.length) && (
              <IconButton onClick={showAttachmentsDialog}>
                <AttachFileIcon />
              </IconButton>
            )}
          </>
        }
        name={invoiceGroup?.agreement?.supplierName}
        status={invoiceGroup?.statusForCustomer}
        isCustomer
      />

      {completed && (
        <Button onClick={switchView}>
          <NotificationsIcon style={{ paddingRight: "12px" }} />
          Dokumentum megtekintése
        </Button>
      )}

      {!completed && (
        <div style={{ marginBottom: "16px", marginTop: "16px" }}>
          <FlexiInfoBar info>
            <Typography fontSize={12}>
              Kérem, igazolja vissza az oldalon található számlákat! Ha ez
              megtörtént, a véglegesítéshez kattintson a Tovább gombra!{" "}
              <Link
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => setActionHelperOpen(true)}
              >
                A visszaigazolásról bővebben ITT olvashat!
              </Link>
            </Typography>
          </FlexiInfoBar>
        </div>
      )}
      <CustomerConfirmationList
        loading={loading}
        invoices={invoiceGroup && invoiceGroup.invoices}
        onModifyAndApproveClick={makeInvoiceActionHandler(
          actions.modifyAndApprove
        )}
        onApproveClick={makeInvoiceActionHandler(actions.approve)}
        onHoldClick={makeInvoiceActionHandler(actions.hold)}
        onRejectClick={invoice => setInvoiceToReject(invoice)}
        onUndoClick={makeInvoiceActionHandler(actions.undo)}
        onShowInvoiceDocumentsClick={invoice =>
          setShowDocumentsInvoice(invoice)
        }
        readOnly={readOnly}
      />
      <FlexiDialog
        open={attachmentsDialogOpen}
        title={intl.formatMessage(messages.groupAttachmentsDialogTitle)}
        onClose={hideAttachmentsDialog}
      >
        <DocumentsCarousel
          showTitle
          documents={invoiceGroup.documents}
          fetcher={guestFetch}
          getUrl={getGroupDocumentUrl}
        />
      </FlexiDialog>
      <FlexiDialog
        open={Boolean(showDocumentsInvoice)}
        title={intl.formatMessage(messages.invoiceAttachmentsDialogTitle)}
        onClose={() => setShowDocumentsInvoice(null)}
      >
        <DocumentsCarousel
          showTitle
          documents={showDocumentsInvoice?.documents}
          fetcher={guestFetch}
          getUrl={docId =>
            docId &&
            getUrlWithCompanyId(
              `/customer/invoice-group/${invoiceGroup.id}/invoice/${showDocumentsInvoice?.id}/document-download/${docId}`
            )
          }
        />
      </FlexiDialog>
      <ConfirmationHelpDialog
        open={actionHelperOpen}
        onClose={() => setActionHelperOpen(false)}
      />
    </FlexiPageLayout>
  );
};

const CustomerInvoiceGroupPage = ({ match, width }) => {
  const id = match?.params?.id;
  const {
    loading,
    invoiceGroup,
    invoiceGroupError,
    guestFetch,
    getGroupDocumentUrl,
    actions,
    reloadInvoiceGroup,
    invoiceGroupUrl,
    signFactorNotificationOptions
  } = useCustomerInvoiceGroupPageData(id);

  const makeInvoiceActionHandler = action => async invoice => {
    const { data, error } = await action(invoice);
    if (data) {
      await reloadInvoiceGroup();
    }
  };

  const allInvoiceOnHold = useMemo(() => {
    return invoiceGroup?.invoices.every(
      item => item.customerConfirmationStatus === "ON_HOLD"
    );
  }, [invoiceGroup]);
  const allInvoiceRejectedOrHold = useMemo(() => {
    return invoiceGroup?.invoices.every(
      item =>
        item.customerConfirmationStatus === "REJECTED" ||
        item.customerConfirmationStatus === "ON_HOLD"
    );
  }, [invoiceGroup]);

  const allInvoiceUntouched = useMemo(() => {
    return invoiceGroup?.invoices.every(
      item => !item.customerConfirmationStatus
    );
  }, [invoiceGroup]);

  const [processFinishedOpen, showProcessFinished] = usePopupState(
    "ProcessFinishedDialog"
  );
  const intl = useIntl();
  const notifyError = useErrorOccurredNotification();
  const faktoriId = invoiceGroup?.faktoriId;
  const completed = invoiceGroup?.statusForCustomer === "APPROVED";
  const onHold = invoiceGroup?.statusForCustomer === "ON_HOLD";
  const rejected = invoiceGroup?.statusForCustomer === "REJECTED";
  const onHoldOrRejected = onHold || rejected;
  const waitingForSign =
    !isSignGetParam &&
    invoiceGroup?.isSignatoryIsAnotherPerson &&
    invoiceGroup?.status ===
      "WAITING_FOR_CUSTOMER_SIGNATURE"; /*||
    invoiceGroup?.status === "WAITING_FOR_ADMIN_CONFIRMATION";*/
  const showSignFactorContract =
    !rejected &&
    (invoiceGroup?.status === "WAITING_FOR_CUSTOMER_SIGNATURE" ||
      invoiceGroup?.status === "WAITING_FOR_ADMIN_CONFIRMATION") &&
    !allInvoiceRejectedOrHold &&
    !waitingForSign;
  const showInvoiceList =
    invoiceGroup?.status === "WAITING_FOR_CUSTOMER_CONFIRMATION" ||
    invoiceGroup?.status === "WAITING_FOR_ADMIN_CONFIRMATION" ||
    waitingForSign;

  const [partnerContactAnchorEl, setPartnerContactAnchorEl] = useState(null);

  const handleClose = () => {
    setPartnerContactAnchorEl(null);
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const invoicesLeft = invoiceGroup?.invoices?.reduce(
    (hasInvoicesLeft, invoice) =>
      hasInvoicesLeft || !invoice.customerConfirmationStatus,
    false
  );

  useEffect(() => {
    if (
      invoicesLeft === false &&
      invoiceGroup?.status === "WAITING_FOR_CUSTOMER_CONFIRMATION"
    ) {
      setConfirmDialogOpen(true);
    }
  }, [invoicesLeft, invoiceGroup?.status]);

  const onNextButtonClick = async () => {
    const { data, error } = await actions.commit();
    if (data) {
      mutate(invoiceGroupUrl, data, false);
    }

    if (error) {
      notifyError(selectErrorNotificationMessage(error));
    }
  };

  const rightSideComponents = [];

  if (showInvoiceList && !rejected) {
    rightSideComponents.push({
      props: {
        children: "ÖSSZES JÓVÁHAGYÁSA",
        onClick: makeInvoiceActionHandler(actions.approveAllInvoice),
        disabled: !allInvoiceUntouched
      },
      mobile: {
        placement: "fab",
        tooltip: "ÖSSZES JÓVÁHAGYÁSA",
        icon: <ArrowForwardIcon />
      }
    });
    rightSideComponents.push({
      props: {
        children: "ÖSSZES VÁRAKOZTATÁSA",
        onClick: makeInvoiceActionHandler(actions.holdAllInvoice),
        variant: "outlined",
        disabled: !allInvoiceUntouched
      },
      mobile: {
        placement: "fab",
        tooltip: "ÖSSZES VÁRAKOZTATÁSA",
        icon: <ArrowForwardIcon />
      }
    });
    rightSideComponents.push({
      divider: true
    });
    rightSideComponents.push({
      props: {
        children: "Tovább",
        disabled: invoicesLeft || onHold,
        onClick: () => setConfirmDialogOpen(true)
      },
      mobile: {
        placement: "fab",
        tooltip: "Tovább",
        icon: <ArrowForwardIcon />
      }
    });
  }
  const history = useHistory();

  if (
    invoiceGroupError?.message === "Token is not valid" ||
    invoiceGroupError?.message === "Invalid token"
  ) {
    history.push("/404");
  }

  const [showOnCompleted, setShowOnCompleted] = useState(null);

  useEffect(() => {
    if (completed) {
      setShowOnCompleted("factor-notification");
    } else {
      window.addEventListener("beforeunload", beforeunload);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [completed]);

  useEffect(() => {
    if (onHoldOrRejected) {
      window.removeEventListener("beforeunload", beforeunload);
    }
  }, [onHoldOrRejected]);

  return (
    <>
      <Prompt
        when={Boolean(invoiceGroup && !completed && !onHoldOrRejected)}
        message={() =>
          `Az összes számla visszaigazolása után, a Mentés gombra kattintva fejezheti be a folyamatot. Ha még nem érkezett meg minden számla, érdemes azokat várakoztatni, így az elfogadott számlák faktorálása folytatódhat.`
        }
      />
      {Boolean(completed ? showOnCompleted === "list" : showInvoiceList) && (
        <>
          <ListSection
            reloadInvoiceGroup={reloadInvoiceGroup}
            invoiceGroup={invoiceGroup}
            rightSideComponents={rightSideComponents}
            faktoriId={faktoriId}
            loading={loading}
            getGroupDocumentUrl={getGroupDocumentUrl}
            guestFetch={guestFetch}
            title={`Számlák`}
            actions={actions}
            completed={completed}
            switchView={() =>
              setShowOnCompleted(
                showOnCompleted === "list" ? "factor-notification" : "list"
              )
            }
            readOnly={completed || rejected}
          />
          <div
            style={{
              display: "inline-flex",
              paddingLeft: "70px",
              paddingBottom: "10px"
            }}
          >
            <Button
              onClick={makeInvoiceActionHandler(actions.approveAllInvoice)}
              disabled={!allInvoiceUntouched}
              style={{ marginRight: "10px" }}
            >
              Összes jóváhagyása
            </Button>
            <Button
              variant={"outlined"}
              onClick={makeInvoiceActionHandler(actions.holdAllInvoice)}
              disabled={!allInvoiceUntouched}
            >
              Összes várakoztatása
            </Button>
          </div>
        </>
      )}

      {Boolean(
        completed
          ? showOnCompleted === "factor-notification"
          : showSignFactorContract
      ) && (
        <FactorNotificationPageContent
          onBackButtonClick={() => history.push("/app/customer-invoice-groups")}
          invoiceGroup={invoiceGroup}
          reloadInvoiceGroup={reloadInvoiceGroup}
          signFactorNotificationOptions={signFactorNotificationOptions}
          suppressPaddingTop
          backButton
          fullWidth
          type={"customer"}
          title={"Faktorálási értesítő tudomásul vétele"}
          onSignedUploadSuccess={showProcessFinished}
          onDownloadClick={signFactorNotification =>
            signFactorNotification.downloadDocument(
              invoiceGroup.financeNotification.documentSignedByCustomer ||
                invoiceGroup.financeNotification.documentGeneratedForCustomer
            )
          }
          renderExtra={
            completed &&
            (() => (
              <Button
                onClick={() =>
                  setShowOnCompleted(
                    showOnCompleted === "list" ? "factor-notification" : "list"
                  )
                }
              >
                <VisibilityIcon style={{ paddingRight: "12px" }} />
                Számlák megtekintése
              </Button>
            ))
          }
        />
      )}
      <FlexiCustomerDialog
        title={"Köszönjük!"}
        // open={processFinishedOpen || waitingForSign}
        darkerBackdrop
        imgSrc={billSmile}
      >
        <Grid
          container
          direction={"column"}
          alignItems={isWidthUp("sm", width) ? "flex-start" : "center"}
        >
          <Grid item xs>
            <Typography
              variant="body1"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                textAlign: isWidthUp("sm", width) ? "flex-start" : "center"
              }}
            >
              {waitingForSign
                ? `Ön minden számlát visszaigazolt, nincs további teendője!`
                : `Az Ön által aláírt dokumentum bekerült a rendszerünkbe! Ezzel nincs további teendője!`}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: isWidthUp("sm", width) ? "flex-start" : "center"
              }}
            >
              Ha többet szeretne megtudni a digitális faktorálásról, látogasson
              el weboldalunkra:
            </Typography>
            <FlexiLink
              style={{ textDecoration: "none" }}
              href={"https://flexibill.hu"}
            >
              <Typography
                style={{
                  color: "#394AB8",
                  textDecoration: "none",
                  textAlign: isWidthUp("sm", width) ? "flex-start" : "center",
                  fontWeight: "bold"
                }}
                variant="body1"
              >
                flexibill.hu
              </Typography>
            </FlexiLink>
          </Grid>
        </Grid>
      </FlexiCustomerDialog>
      <ConfirmationDialog
        customer={true}
        onNextButtonClick={onNextButtonClick}
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
        }}
        waiting={allInvoiceOnHold}
      />
    </>
  );
};

export default withWidth()(CustomerInvoiceGroupPage);
